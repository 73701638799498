import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import axiosRetry from "axios-retry";
import { getEexchangeRateFail, getEexchangeRateSuccess } from "../actions";
import { EXCHANGE_RATE } from "../actions/types";

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

const API_URL = process.env.REACT_APP_API_EXCHANGE;

const dataRequest = async () => {
  return await axios
    .get(API_URL)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

// WORKER
function* getData({ payload }) {
  try {
    const response = yield call(dataRequest);
    yield put(getEexchangeRateSuccess(response.data));
  } catch (error) {
    yield put(getEexchangeRateFail(error));
  }
}

// WATCHER
export function* getDataWatcher() {
  yield takeEvery(EXCHANGE_RATE, getData);
}

export default function* rootSaga() {
  yield all([fork(getDataWatcher)]);
}
