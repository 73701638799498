import { all } from "redux-saga/effects";
import GetDataSagas from "./GetDataSagas";
import AuthenSagas from "./AuthenSagas";
import GetProductSagas from "./GetProductSagas";
import GetAccountSagas from "./GetAccountSagas";
import GetProductIdSagas from "./GetProductIdSagas";
import GetProductOwnerSagas from "./GetProductOwnerSagas";
import PostFavoriteSagas from "./PostFavoriteSagas";
import ExchangeRateSagas from "./ExchangeRateSagas";
import GetProductFavoriteSagas from "./GetProductFavoriteSagas";
import GetProfileSagas from "./GetProfileSagas";
import GetProductBidingSagas from "./GetProductBidingSagas";
import GetHomepageSagas from "./GetHomepageSagas";

export default function* rootSaga() {
  yield all([
    GetDataSagas(),
    AuthenSagas(),
    GetProductSagas(),
    GetAccountSagas(),
    GetProductIdSagas(),
    GetProductOwnerSagas(),
    PostFavoriteSagas(),
    ExchangeRateSagas(),
    GetProductFavoriteSagas(),
    GetProfileSagas(),
    GetProductBidingSagas(),
    GetHomepageSagas(),
  ]);
}
