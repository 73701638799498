import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { Link } from "wouter";
import ExploreCard from "../components/ExploreCard";
import { getProductId } from "../redux/actions";
// import { photoUrl, showAddress } from "../services/content-functions";
import LoadingOverlay from "react-loading-overlay";

import AlertMsg from "../components/AlertMsg";
// import { claim } from "../services/contract-functions/antique";
import { redeem } from "../services/api/redeem";

const AssetClaim = ({ params, provider, getProductId, data }) => {
  const { id } = params;
  const [submitButton, setSubmitButton] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [show, setShow] = useState(false);

  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const toggleShowSuccess = () => setShowToastSuccess(!showToastSuccess);

  const [showToastError, setShowToastError] = useState(false);
  const toggleShowError = () => setShowToastError(!showToastError);

  useEffect(() => {
    const fetchData = async () => {
      await getProductId(id);
    };
    fetchData();
  }, [getProductId, id]);

  useEffect(() => {
    if (name !== "" && address !== "") {
      setSubmitButton(true);
    }
  }, [setSubmitButton, name, address]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const handleBurnToken = async (tokenId, productId) => {
    setSubmitButton(false);
    setShow(true);

    const result = await redeem(productId, name, address);
    if (result === "SUCCESS_MSG") {
      console.log("result redeem: ", result);
      setShow(false);
      await setShowToastSuccess(true);
      await delay(1000);
      window.location = "/account";
    } else {
      setShow(false);
      setShowToastError(true);
      setSubmitButton(true);
    }

    // const result = await claim(provider, tokenId, productId);
    // if (result === "SUCCESS_MSG") {
    //   console.log("result sell: ", result);
    //   await setShow(false);
    //   await setShowToastSuccess(true);
    //   await delay(1000);
    //   window.location = "/account";
    // } else {
    //   setShow(false);
    //   setShowToastError(true);
    //   setSubmitButton(true);
    // }
  };

  // console.log("data==>", data);

  return data !== null ? (
    <>
      <Card>
        <Card.Header>
          <Container className="block-fixed text-left">
            <Row>
              <Col md={4}>
                <Row>
                  <Col md={12} className="align-center">
                    <Link to={`/assets/${id}`} className="text-black">
                      <MdKeyboardArrowLeft size={32} className="text-link" />
                    </Link>
                  </Col>
                  {/* <Col md={2}>
                    <Image
                      rounded={true}
                      thumbnail={true}
                      src={photoUrl(data.data.pro_pic)}
                    />
                  </Col>
                  <Col>
                    <p className="text-gray nopadding">SiamArt</p>
                    <p className="nopadding">
                      <Link to={`/collection/${data.data.pro_owner}`}>
                        #
                        {data.seller.name !== ""
                          ? data.seller.name
                          : showAddress(data.seller.name)}
                      </Link>
                    </p>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </Container>
        </Card.Header>
      </Card>
      <Container className="block-fixed bg-content-main p-4">
        <Row className="mt-2 frm-sale-item">
          <Col md={5}>
            <h1>Redeem and burn item</h1>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                size="lg"
                rows={4}
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Tel.</Form.Label>
              <Form.Control
                type="text"
                size="lg"
                rows={4}
                placeholder=""
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>
            <hr />

            <Button
              disabled={submitButton === false ? true : false}
              size="lg"
              className="mt-4 ml-2"
              onClick={handleBurnToken.bind(
                null,
                data.data.pro_minttoken,
                data.data.id_pro
              )}
            >
              Redeem & Burn
            </Button>
          </Col>
          <Col md={{ offset: 1, span: 3 }} className="asset-info">
            <h5>Preview</h5>
            <Row className="explore-result">
              <Col md={12} className="asset-collection">
                <ExploreCard
                  provider={provider}
                  link={false}
                  data={data.data}
                  price={null}
                  widthVal={12}
                  favStatus={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <AlertMsg
          showToast={showToastSuccess}
          toggleShow={toggleShowSuccess}
          title="Your redeem and burn was submitted successfully"
          msg="success"
        />
        <AlertMsg
          showToast={showToastError}
          toggleShow={toggleShowError}
          title="Your redeem and burn was submitted fail!"
          msg="danger"
        />

        <Modal
          show={show}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>
              <Spinner animation="border" size="sm" />
              &nbsp;Redeem & Burn
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please confirm and wait for confirmation on the blockchain.
          </Modal.Body>
        </Modal>
      </Container>
    </>
  ) : (
    <LoadingOverlay active={true} spinner text="Loading..."></LoadingOverlay>
  );
};

const mapStateToProps = ({ productId }) => {
  return { data: productId.result };
};

export default connect(mapStateToProps, { getProductId })(AssetClaim);
