import React from "react";
import { Row } from "react-bootstrap";
import ExploreCard from "./ExploreCard";

const ProfileTabsRedeem = ({ provider, data }) => {
  return data.length > 0 ? (
    <div className="explore-result account-tabs-content">
      <Row className="explore-result pl-2 pr-2">
        {data.map((v, index) => (
          <ExploreCard
            provider={provider}
            key={index}
            type="owner"
            data={v}
            favStatus={false}
            link={false}
            burn={true}
          />
        ))}
      </Row>
    </div>
  ) : (
    <div className="account-tabs-content">
      <h2 className="text-center pt-5 pb-5">No items to display</h2>
    </div>
  );
};

export default ProfileTabsRedeem;
