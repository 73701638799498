import { AUTHEN, AUTHEN_SUCCESS, GET_DATA_FAIL, LOGOUT_REQUEST } from "./types";

export const authen = (payload) => {
  return {
    type: AUTHEN,
    payload,
  };
};

export const authenSuccess = (payload) => {
  return {
    type: AUTHEN_SUCCESS,
    payload,
  };
};

export const authenFail = (payload) => {
  return {
    type: GET_DATA_FAIL,
    payload,
  };
};

export const logout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};
