import {
  PRODUCT_REQUEST,
  PRODUCT_REQUEST_FAIL,
  PRODUCT_REQUEST_SUCCESS,
} from "./types";

export const getProduct = () => {
  return {
    type: PRODUCT_REQUEST,
  };
};

export const getProductSuccess = (payload) => {
  return {
    type: PRODUCT_REQUEST_SUCCESS,
    payload,
  };
};

export const getProductFail = (payload) => {
  return {
    type: PRODUCT_REQUEST_FAIL,
    payload,
  };
};
