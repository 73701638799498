import { GET_DATA, GET_DATA_FAIL, GET_DATA_SUCCESS } from "./types";

export const getData = () => {
  return {
    type: GET_DATA,
  };
};

export const getDataSuccess = (payload) => {
  return {
    type: GET_DATA_SUCCESS,
    payload,
  };
};

export const getDataFail = (payload) => {
  return {
    type: GET_DATA_FAIL,
    payload,
  };
};
