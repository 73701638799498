import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  ListGroup,
  OverlayTrigger,
  Row,
  Tooltip,
  Container,
  Modal,
  Spinner,
} from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { connect } from "react-redux";
import {
  MdAccountBox,
  MdAttachMoney,
  // MdEdit,
  // MdInfo,
  MdInfoOutline,
  MdLocalOffer,
  MdNotifications,
  MdSecurity,
} from "react-icons/md";
import { getAccount } from "../redux/actions";
import { Link } from "wouter";
import { FaCheckCircle, FaRegClipboard } from "react-icons/fa";
import ProfilePhoto from "../components/ProfilePhoto";
import ProfileCover from "../components/ProfileCover";
import LoadingOverlay from "react-loading-overlay";
import { UpdateAccountVerifyEmail } from "../services/api/verify-email";
import AlertMsg from "../components/AlertMsg";

const AccountSettings = ({ signedInAddress, data, getAccount }) => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const toggleShowSuccess = () => setShowToastSuccess(!showToastSuccess);
  const [showToastError, setShowToastError] = useState(false);
  const toggleShowError = () => setShowToastError(!showToastError);

  const tooltipCopiedAddress = (props) => (
    <Tooltip {...props} id="tooltip-top">
      Copied to clipboard!
    </Tooltip>
  );

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const save = async () => {
    await setLoading(true);

    if (data.result.is_active === 1) {
      const result = await UpdateAccountVerifyEmail(name, email);
      if (result === "SUCCESS_MSG") {
        setShowModal(true);
        setLoading(false);
        toggleShowSuccess();
      } else if (result === "ERROR_MSG") {
        setLoading(false);
        toggleShowError();
      }
    } else {
      const result2 = await UpdateAccountVerifyEmail(name);
      if (result2 === "SUCCESS_MSG") {
        setShowModal(true);
        setLoading(false);
        toggleShowSuccess();
        delay(3000);
        window.location = "/account";
      } else if (result2 === "ERROR_MSG") {
        setLoading(false);
        toggleShowError();
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      await getAccount();
    }
    if (data.result === null && data.fetching === false) {
      fetchData();
    }
  }, [getAccount, data]);

  useEffect(() => {
    async function setData() {
      setName(data.result.name);
      setEmail(data.result.email);
    }
    if (data.result !== null) {
      setData();
    }
  }, [data]);

  useEffect(() => {
    // console.log("name===>", name);
    // console.log("email===>", email);
    setDisabled(name === null || email === null ? true : false);
  }, [setDisabled, name, email]);

  return data.result !== null ? (
    <Container className="bg-content-main block-fixed">
      <div className="block-account-detail">
        <Row>
          <Col md={3} className="block-left">
            <h6>SETTINGS</h6>
            <ListGroup>
              <ListGroup.Item>
                <Link to="/account/settings?tab=profile">
                  <MdAccountBox size={28} /> Profile
                </Link>
              </ListGroup.Item>
              <ListGroup.Item>
                <MdNotifications size={28} /> Notifications
              </ListGroup.Item>
              <ListGroup.Item>
                <MdLocalOffer size={28} /> Offers
              </ListGroup.Item>
              <ListGroup.Item>
                <MdSecurity size={28} /> Account Support
              </ListGroup.Item>
              <ListGroup.Item>
                <MdAttachMoney size={28} /> Earnings
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={5} className="block-right">
            <h1>Profile Settings</h1>
            <p className="mt-4">
              <span className="req">*</span> Required fields
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Name <span className="req">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {/* <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text> */}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Email address <span className="req">*</span>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    disabled={data.result.is_active === 2 ? true : false}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <InputGroup.Text>
                    <span className="text-secondary">
                      <span className="text-gray pointer address-area">
                        <FaCheckCircle
                          size={20}
                          color={
                            data.result.is_active === 2 ? "#00b700" : "#cccccc"
                          }
                        />
                      </span>
                    </span>
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Wallet Address</Form.Label>
                <InputGroup>
                  <Form.Control type="text" disabled value={signedInAddress} />
                  <InputGroup.Text>
                    <OverlayTrigger
                      key="hover"
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">Copy</Tooltip>}
                    >
                      <CopyToClipboard text={signedInAddress}>
                        <span className="text-secondary">
                          <OverlayTrigger
                            key="address"
                            placement="top"
                            trigger="click"
                            rootClose={true}
                            onHide
                            rootCloseEvent="mousedown"
                            overlay={tooltipCopiedAddress}
                          >
                            <span className="text-gray pointer address-area">
                              <FaRegClipboard size={20} />
                            </span>
                          </OverlayTrigger>
                        </span>
                      </CopyToClipboard>
                    </OverlayTrigger>
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Button
                variant="primary"
                onClick={save}
                disabled={loading === true || disabled === true ? true : false}
              >
                {loading === true && <Spinner animation="border" size="sm" />}

                {data.result.is_active === 1
                  ? "Update & verify email"
                  : "Update"}
              </Button>
            </Form>
          </Col>
          <Col md={4} className="p-5">
            <div className="profile-center text-center">
              <strong>
                Profile Image <MdInfoOutline size={24} />
              </strong>
              <ProfilePhoto path={data.result.profile_photo_path} />
              <label htmlFor="file-upload" className="file-upload">
                {/* <div className="bg-edit">
                  <MdEdit size={26} />
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/x-png, image/jpeg"
                    onChange={onFileChange}
                  />
                </div> */}
              </label>
            </div>

            <div className="profile-center text-center mt-5">
              <strong>
                Profile Banner <MdInfoOutline size={24} />
              </strong>
              <ProfileCover path="" />
              <label htmlFor="file-upload" className="file-upload">
                {/* <div className="bg-edit">
                  <MdEdit size={26} />
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/x-png, image/jpeg"
                    onChange={onFileChange}
                  />
                </div> */}
              </label>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Plase verify your account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              src={`${process.env.PUBLIC_URL}/icons/email_newsletter_subscription_icon.png`}
              alt="circle correct"
            />
          </div>
          <p className="mt-2">
            We have send a verification mail to {data.result.email} Plase
            activate your account with the link in this mail.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="width-100"
            onClick={() => {
              setShowModal(false);
              getAccount();
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <AlertMsg
        showToast={showToastSuccess}
        toggleShow={toggleShowSuccess}
        title="Your update/verify account successfully"
        msg="success"
      />

      <AlertMsg
        showToast={showToastError}
        toggleShow={toggleShowError}
        title="Your update/verify account fail!"
        msg="danger"
      />
    </Container>
  ) : (
    <LoadingOverlay active={true} spinner text="Loading..."></LoadingOverlay>
  );
};

const mapStateToProps = ({ account }) => {
  return { data: account };
};

export default connect(mapStateToProps, {
  getAccount,
})(AccountSettings);
