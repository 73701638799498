import React, { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

const Asset = ({ asset }) => {
    const [meta, setMeta] = React.useState(null);
    useEffect(() => {
        const fetchMetadata = async () => {
            const response = await fetch(asset);
            const data = await response.json();
            console.log(data);
            setMeta(data);
        };
        fetchMetadata();
    }, [asset]);

    return (
        <Col style={{marginBottom: '20px'}}>
            {meta !== null && (
                <Card style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={meta.image !== undefined && meta.image} />
                    <Card.Body>
                        <Card.Title>{meta.name !== undefined && meta.name}</Card.Title>
                        <Card.Text>{meta.description !== undefined && meta.description}</Card.Text>
                        <Button variant="primary">Transfer</Button>&nbsp;
                        <Button variant="secondary">Burn</Button>
                    </Card.Body>
                </Card>
            )}
        </Col>
    )
}

export default Asset;