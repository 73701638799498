import {
  EXCHANGE_RATE,
  EXCHANGE_RATE_FAIL,
  EXCHANGE_RATE_SUCCESS,
} from "./types";

export const getEexchangeRate = (payload) => {
  return {
    type: EXCHANGE_RATE,
    payload,
  };
};

export const getEexchangeRateSuccess = (payload) => {
  return {
    type: EXCHANGE_RATE_SUCCESS,
    payload,
  };
};

export const getEexchangeRateFail = (payload) => {
  return {
    type: EXCHANGE_RATE_FAIL,
    payload,
  };
};
