import {
  PRODUCT_OWNER_REQUEST,
  PRODUCT_OWNER_REQUEST_FAIL,
  PRODUCT_OWNER_REQUEST_SUCCESS,
} from "./types";

export const getProductOwner = () => {
  return {
    type: PRODUCT_OWNER_REQUEST,
  };
};

export const getProductOwnerSuccess = (payload) => {
  return {
    type: PRODUCT_OWNER_REQUEST_SUCCESS,
    payload,
  };
};

export const getProductOwnerFail = (payload) => {
  return {
    type: PRODUCT_OWNER_REQUEST_FAIL,
    payload,
  };
};
