import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "wouter";

const DialogVerifyEmail = ({ showVerify }) => {
  return (
    <Modal show={showVerify} size="md" className="dialog-checkout" centered>
      <Modal.Header>
        <Modal.Title>Alert verify account</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">Plase verify your email.</Modal.Body>
      <Modal.Footer>
        <Link to="/account/settings">
          <Button size="lg" variant="primary">
            Verify Now
          </Button>
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default DialogVerifyEmail;
