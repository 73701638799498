import { AbiCoder } from "@ethersproject/abi";
// import { verifyMessage } from "@ethersproject/wallet";
// import { getAddress } from "@ethersproject/address";


// const SUCCESS_MSG = "Success! Transaction has been submitted to the network. Please wait for confirmation on the blockchain.";
const EXTRACT_ERROR_MESSAGE = /(?<="message":")(.*?)(?=")/g;

// const ANTIQUE_NFT_ADDRESS = "0xD638C393F4eCB9BdDDB60a698db4d91daE4323aB"; // Kovan Testnet
// const ANTIQUE_NFT_ADDRESS = process.env.REACT_APP_ANTIQUE_NFT_ADDRESS
// const RECEIPT_NFT_ADDRESS = process.env.REACT_APP_RECEIPT_NFT_ADDRESS
// const MARKET_ADDRESS = process.env.REACT_APP_MARKET_ADDRESS

/*
 *  helper functions
 */

function catchError(error) {
    console.error(error.message);

    // try to extract error message, otherwise return raw error
    let formatted_error;

    if (error.message.startsWith("invalid ENS name")) {
        formatted_error = "Missing or invalid parameter.";
    } else if (error.message.startsWith("invalid BigNumber string")) {
        formatted_error = "Invalid number parameter."
    } else {
        try {
            let errors = JSON.stringify(error).match(EXTRACT_ERROR_MESSAGE);
            formatted_error = errors[errors.length - 1];
        } catch (e) {
            formatted_error = error.message;
        }
    }

    return formatted_error;
}

// Helper function to prevent ambiguous failure message when dates aren't passed
// function convertToZeroIfBlank(num) {
//     return parseInt(num) || 0;
// }

// function toUnixTime(date) {
//     // Return date if not a Date object
//     if (Object.prototype.toString.call(date) !== "[object Date]") return date;
//     return parseInt((date.getTime() / 1000).toFixed(0));
// }

export async function getBlockNumber(w3provider) {
    return w3provider.getBlockNumber();
}

export async function getCoinBalance(w3provider, address) {
    return await w3provider.getBalance(address);
}

export function encodeParameters(types, values) {
    let abi = new AbiCoder();
    return abi.encode(types, values);
}

export function decodeParameters(types, values) {
    let abi = new AbiCoder();
    return abi.decode(types, values);
}

export function formatDate(timestamp) {
    if (timestamp === 0) {
        return "None";
    } else {
        return (new Date(timestamp * 1000)).toLocaleString();
    }
}

/*
 *  Smart contract functions
 */

// export async function signMessage(w3provider, signedInAddress) {
//     // const hash = keccak256(toUtf8Bytes("nonce=" + 123456));
//     const hash = "nonce=123456";
//     console.log("Hash:", hash);
//     const signer = w3provider.getSigner();

//     var result;
//     try {
//         const signature = await signer.signMessage(hash);
//         console.log("Signature:", signature);
//         var address = verifyMessage(hash, signature);
//         // send sigin(address, signature, nonce)
//         if (address === getAddress(signedInAddress)) {
//             console.log("Signature verified");
//         } else {
//             console.log("Signature not verified");
//         }
//     } catch (error) {
//         result = catchError(error);
//     }
//     return result;
// }

export async function signMessage(w3provider, message) {
    var signature;
    try {
        signature = await w3provider.getSigner().signMessage(message);
    } catch (error) {
        signature = catchError(error);
    }
    return signature;
}
