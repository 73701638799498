import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { PRODUCT_ID_REQUEST } from "../actions/types";
import { getProductIdFail, getProductIdSuccess } from "../actions";
import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

const API_URL = process.env.REACT_APP_API_URL;

const dataRequest = async (payload) => {
  let token;
  try {
    token = await localStorage.getItem("_syart_member");
  } catch (err) {}

  if (token === null) {
    return await axios
      .get(`${API_URL}/productshow/${payload}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  } else {
    return await axios
      .get(`${API_URL}/v1/productshow/${payload}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }
};

// WORKER
function* getData({ payload }) {
  // try {
  const response = yield call(dataRequest, payload);

  if (response.data.success === true) {
    yield put(getProductIdSuccess(response.data));
  } else {
    yield put(getProductIdFail(response));
  }
  // } catch (error) {
  //   yield put(getProductIdFail(error));
  // }
}

// WATCHER
export function* getDataWatcher() {
  yield takeEvery(PRODUCT_ID_REQUEST, getData);
}

export default function* rootSaga() {
  yield all([fork(getDataWatcher)]);
}
