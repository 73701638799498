import React from "react";
import { Accordion, Col, Image, Modal, Row, Spinner } from "react-bootstrap";
import { MdCheckCircle } from "react-icons/md";
import { Link } from "wouter";
import { convertEthToUsd, photoUrl } from "../services/content-functions";

const DialogSaleComplete = ({
  show,
  handleClose,
  data,
  price,
  checking,
  exchange,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      className="dialog-checkout"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Complete your listing</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5 pt-4 pb-4 checkout">
        <Row>
          <Col>
            <Row>
              <Col md={3} className="align-center">
                <Image
                  rounded={true}
                  thumbnail={true}
                  src={photoUrl(data.data.pro_pic)}
                />
              </Col>
              <Col>
                <p className="text-gray nopadding">SiamArt</p>
                <p className="nopadding">
                  <Link to={`/collection/${data.seller.name}`}>
                    #{data.seller.name}
                  </Link>
                </p>
                <p className="text-gray nopadding">Quantity: 1</p>
              </Col>
            </Row>
          </Col>
          <Col className="text-right">
            <p className="text-gray nopadding">Price</p>
            <p className="nopadding">
              <img
                src={`${process.env.PUBLIC_URL}/images/ETH.png`}
                height={16}
                alt="ETH"
              />{" "}
              {price}
            </p>
            <p className="text-gray nopadding">
              {exchange !== null &&
                "$" +
                  convertEthToUsd(
                    exchange.ETH.USD,
                    parseFloat(price === "" ? 0 : price).toFixed(4)
                  )}{" "}
              USD
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Accordion defaultActiveKey="0" className="modal-sale-complete">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {checking >= 1 ? (
                    <MdCheckCircle className="icon green" size={32} />
                  ) : (
                    <Spinner animation="border" role="status"></Spinner>
                  )}{" "}
                  Initialize your wallet
                </Accordion.Header>
                <Accordion.Body>
                  To get set up for selling on SiamArt for the first time, you
                  must initialize your wallet, which requires a one-time gas
                  fee.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  {checking >= 2 ? (
                    <MdCheckCircle className="icon green" size={32} />
                  ) : (
                    <Spinner animation="border" role="status"></Spinner>
                  )}{" "}
                  Approve this item for sale
                </Accordion.Header>
                <Accordion.Body>
                  To get set up for auction listings for the first time, you
                  must approve this item for sale, which requires a one-time gas
                  fee.
                </Accordion.Body>
              </Accordion.Item>{" "}
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  {checking >= 3 ? (
                    <MdCheckCircle className="icon green" size={32} />
                  ) : (
                    <Spinner animation="border" role="status"></Spinner>
                  )}{" "}
                  Confirm {price} ETH listing
                </Accordion.Header>
                <Accordion.Body>
                  Accept the signature request in your wallet and wait for your
                  listing to process.
                  <span>Waiting for signature...</span>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default DialogSaleComplete;
