import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";

const AlertMsg = ({ showToast, toggleShow, title, msg = "success" }) => {
  return (
    <ToastContainer position="bottom-end" className="p-3">
      <Toast
        show={showToast}
        bg={msg}
        onClose={toggleShow}
        delay={3000}
        autohide
        animation
      >
        <Toast.Header closeVariant="white">
          <strong className="me-auto">
            {msg === "danger" ? <MdInfoOutline size={24} /> : ""} {title}
          </strong>
        </Toast.Header>
      </Toast>
    </ToastContainer>
  );
};

export default AlertMsg;
