import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import axiosRetry from "axios-retry";
import { getProductBidingFail, getProductBidingSuccess } from "../actions";
import { BID_LIST_REQUEST } from "../actions/types";
// import { authorizationHeader } from "../../utils/apiUtil";

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

const API_URL = process.env.REACT_APP_API_URL;

const dataRequest = async () => {
  let token;
  try {
    token = await localStorage.getItem("_syart_member");
  } catch (err) {}

  return await axios
    .get(`${API_URL}/v1/bid/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

// WORKER
function* getData() {
  try {
    const response = yield call(dataRequest);

    if (response.data.success === true) {
      yield put(getProductBidingSuccess(response.data.data));
    } else {
      yield put(getProductBidingFail(response));
    }
  } catch (error) {
    yield put(getProductBidingFail(error));
  }
}

// WATCHER
export function* getDataWatcher() {
  yield takeEvery(BID_LIST_REQUEST, getData);
}

export default function* rootSaga() {
  yield all([fork(getDataWatcher)]);
}
