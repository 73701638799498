import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getProduct } from "../redux/actions";
import ExploreCard from "../components/ExploreCard";
import { Col, Container, Form, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";

const Assets = ({ provider, data, getProduct }) => {
  const [recently, setRecently] = useState("listed");
  const [type, setType] = useState("all");

  useEffect(() => {
    const fetchData = async () => {
      await getProduct();
    };
    fetchData();
  }, [getProduct]);

  return data !== null ? (
    <Container className="bg-content-main">
      <Row className="explore-header">
        <Col md={6}>{data.length.toLocaleString()} items</Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Select
              id="filterItem"
              size="lg"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="all">All items</option>
              <option value="1">Buy Now</option>
              <option value="2">Bid</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Select
              id="filterSort"
              size="lg"
              onChange={(e) => setRecently(e.target.value)}
              value={recently}
            >
              <option value="listed">Recently Listed</option>
              <option value="created">Recently Created</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="explore-result mt-5">
        {data !== "" &&
        data.filter((x) =>
          type === "all"
            ? x.fk_sell_type === 1 || x.fk_sell_type === 2
            : type === "1"
            ? x.fk_sell_type === 1
            : x.fk_sell_type === 2
        ).length > 0 ? (
          data
            .filter((x) =>
              type === "all"
                ? x.fk_sell_type === 1 || x.fk_sell_type === 2
                : type === "1"
                ? x.fk_sell_type === 1
                : x.fk_sell_type === 2
            )
            .sort((a, b) =>
              recently === "listed"
                ? b.updated_at.localeCompare(a.updated_at)
                : b.created_at.localeCompare(a.created_at)
            )
            .map((v, index) => (
              <ExploreCard
                provider={provider}
                key={index}
                type={v.fk_sell_type === 1 ? "buy" : "bid"}
                data={v}
              />
            ))
        ) : (
          <h2 className="text-center pt-5 pb-5">No items to display</h2>
        )}
      </Row>
    </Container>
  ) : (
    <LoadingOverlay active={true} spinner text="Loading..."></LoadingOverlay>
  );
};

const mapStateToProps = ({ product }) => {
  return { data: product.result };
};

export default connect(mapStateToProps, {
  getProduct,
})(Assets);
