import React, { forwardRef, useEffect, useState } from "react";
import { signMessage } from "../services/contract-functions/utils";
import { connect } from "react-redux";
import { authen } from "../redux/actions";
import LoadingOverlay from "react-loading-overlay";

const Sign = forwardRef(({ provider, signedInAddress, auth, authen }, ref) => {
  const [signature, setSignature] = useState(null);
  const [message] = useState(
    "I have read and accept the terms and conditions (https://siam.art) of this app. Please sign me in!"
  );

  useEffect(() => {
    const fetchSignature = async () => {
      console.log("fetchSignature");
      if (provider) {
        var signature = await signMessage(provider, message);
        setSignature(signature);
      }
    };
    if (signature === null) {
      fetchSignature();
    }
  }, [provider, message, signature]);

  useEffect(() => {
    const signin = async () => {
      // console.log("message=", message);
      // console.log("signature=", signature);
      // console.log("signedInAddress=", signedInAddress);
      // console.log("auth=", auth);

      await authen({
        message: message,
        signature: signature,
        eth_address: signedInAddress,
      });
    };

    if (signature !== null && auth.result === null && auth.fetching === false) {
      signin();
    }
  }, [auth, authen, message, signature, signedInAddress]);

  return (
    <>
      <LoadingOverlay active={true} spinner text="Waiting..."></LoadingOverlay>
    </>
  );
});

const mapStateToProps = ({ authen }) => {
  return { auth: authen };
};

export default connect(mapStateToProps, {
  authen,
})(Sign);
