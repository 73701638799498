import React from "react";
import { Button, Modal } from "react-bootstrap";

const DialogCancelListing = ({ show, handleClose, data, submit }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      className="dialog-checkout"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you sure you want to cancel your listing?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5 pt-4 pb-2 checkout">
        <p>
          Canceling your listing will unpublish this sale from OpenSea and
          requires a transaction to make sure it will never be fulfillable.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button size="lg" variant="outline-secondary" onClick={handleClose}>
          Never mind
        </Button>

        <Button size="lg" variant="primary" onClick={submit}>
          Cancel listing
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DialogCancelListing;
