import { HOMEPAGE_FAIL, HOMEPAGE_REQUEST, HOMEPAGE_SUCCESS } from "./types";

export const getHomepage = () => {
  return {
    type: HOMEPAGE_REQUEST,
  };
};

export const getHomepageSuccess = (payload) => {
  return {
    type: HOMEPAGE_SUCCESS,
    payload,
  };
};

export const getHomepageFail = (payload) => {
  return {
    type: HOMEPAGE_FAIL,
    payload,
  };
};
