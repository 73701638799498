import React from "react";
import { Accordion, Button, CloseButton, Modal } from "react-bootstrap";
import { Link } from "wouter";
import { photoUrl } from "../services/content-functions";

const DialogSaleView = ({ show, closeRedirect, pid, photo }) => {
  return (
    <Modal
      show={show}
      onHide={closeRedirect}
      size="lg"
      className="dialog-checkout"
      centered
    >
      <Modal.Header>
        <Modal.Title>Your item is now listed for sale</Modal.Title>
        <Link to={`/assets/${pid}`}>
          <CloseButton />
        </Link>
      </Modal.Header>
      <Modal.Body className="p-5 pt-4 pb-2 checkout">
        <div className="text-center">
          <img src={photoUrl(photo)} height={150} alt="product" />
        </div>
        <Accordion defaultActiveKey="0" className="mt-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Enable email notifications</Accordion.Header>
            <Accordion.Body>
              <p>
                Enter your email address in your account settings so we can let
                you know when your listing sells or receives offers.
              </p>
              <Link to="/account">
                <Button variant="outline-primary">
                  Manage account settings
                </Button>
              </Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Link to={`/assets/${pid}`}>
          <Button size="lg" variant="primary">
            View Item
          </Button>
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default DialogSaleView;
