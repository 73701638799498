import {
  FAVORITE_REQUEST,
  FAVORITE_REQUEST_FAIL,
  FAVORITE_REQUEST_SUCCESS,
} from "./types";

export const getProductFav = () => {
  return {
    type: FAVORITE_REQUEST,
  };
};

export const getProductFavSuccess = (payload) => {
  return {
    type: FAVORITE_REQUEST_SUCCESS,
    payload,
  };
};

export const getProductFavFail = (payload) => {
  return {
    type: FAVORITE_REQUEST_FAIL,
    payload,
  };
};
