import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  Image,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  MdAttachMoney,
  // MdCheckCircleOutline,
  MdInfoOutline,
  MdKeyboardArrowLeft,
  MdTimelapse,
} from "react-icons/md";
import { Link } from "wouter";
import ExploreCard from "../components/ExploreCard";
import DialogSaleView from "../components/DialogSaleView";
import DialogSaleComplete from "../components/DialogSaleComplete";
import { getProductId } from "../redux/actions";
import {
  convertEthToUsd,
  photoUrl,
  showAddress,
} from "../services/content-functions";
import LoadingOverlay from "react-loading-overlay";
import {
  approveToken,
  auctionCreate,
  listToken,
} from "../services/contract-functions/market";

import TextField from "@mui/material/TextField";
import moment from "moment";
import AlertMsg from "../components/AlertMsg";

const AssetSell = ({ params, provider, getProductId, data, exchange }) => {
  const { id } = params;
  const [submitButton, setSubmitButton] = useState(false);
  const [type, setType] = useState(1);
  const [currency] = useState("ETH");
  const [price, setPrice] = useState("");
  const [durationDatetime, setDurationDatetime] = useState(
    moment(new Date()).add(1, "days").format("Y-MM-DTHH:mm")
  );
  const [showComplete, setShowComplete] = useState(false);
  const [show, setShow] = useState(false);
  const [checking, setChecking] = useState(0);
  const [approved, setApproved] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [showListing, setShowListing] = useState(false);

  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const toggleShowSuccess = () => setShowToastSuccess(!showToastSuccess);

  const [showToastError, setShowToastError] = useState(false);
  const toggleShowError = () => setShowToastError(!showToastError);

  const [showToastApproveSuccess, setShowToastApproveSuccess] = useState(false);
  const toggleShowApproveSuccess = () =>
    setShowToastApproveSuccess(!showToastApproveSuccess);
  const [showToastApproveError, setShowToastApproveError] = useState(false);
  const toggleShowApproveError = () =>
    setShowToastApproveError(!showToastApproveError);

  useEffect(() => {
    const fetchData = async () => {
      await getProductId(id);
    };
    fetchData();
  }, [getProductId, id]);

  useEffect(() => {
    if (data !== null && data.data.pro_status === 16) {
      setApproved(true);
    }
  }, [data]);

  const handleModalComplete = () => {
    setShowComplete(true);
  };

  const handleModalCompleteClose = () => {
    setShowComplete(false);
    setShow(true);
  };

  const closeRedirect = () => {
    setShow(false);
  };

  const changeType = () => {
    setType(type === 1 ? 2 : 1);
  };

  useEffect(() => {
    if (
      (type === 1 &&
        price !== "" &&
        price > 0 &&
        durationDatetime !== "" &&
        approved === true) ||
      (type === 2 && price !== "" && price > 0 && durationDatetime !== "")
    ) {
      setSubmitButton(true);
    }
  }, [setSubmitButton, durationDatetime, price, approved, type]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const handleListToken = async (tokenId, productId, listId) => {
    setSubmitButton(false);
    setShowListing(true);

    const durationVal = moment(durationDatetime).format("Y-MM-D HH:mm:ss");

    const result = await listToken(
      provider,
      tokenId,
      productId,
      type,
      price,
      durationVal,
      listId
    );
    if (result === "SUCCESS_MSG") {
      console.log("result sell: ", result);
      handleModalComplete();

      await setChecking(1);

      await delay(1000);
      await setChecking(2);

      await delay(1000);
      await setChecking(3);

      setShowToastSuccess(true);

      await handleModalCompleteClose();
    } else {
      await delay(1000);
      setShowListing(false);
      setShowToastError(true);
      setSubmitButton(true);
    }
  };

  const handleAuctionCreate = async (tokenId, productId, listId) => {
    setSubmitButton(false);
    setShowListing(true);

    const durationVal = moment(durationDatetime).format("Y-MM-D HH:mm:ss");

    const result = await auctionCreate(
      provider,
      tokenId,
      productId,
      type,
      price,
      durationVal,
      listId
    );
    if (result === "SUCCESS_MSG") {
      console.log("result sell: ", result);
      handleModalComplete();

      await setChecking(1);

      await delay(1000);
      await setChecking(2);

      await delay(1000);
      await setChecking(3);

      setShowToastSuccess(true);

      await handleModalCompleteClose();
    } else {
      await delay(1000);
      setShowListing(false);
      setShowToastError(true);
      setSubmitButton(true);
    }
  };

  const handleApproveToken = async (productToken, productId) => {
    setShowApprove(true);

    const result = await approveToken(provider, productToken, productId);
    console.log("result approve: ", result);

    if (result === "SUCCESS_MSG") {
      await setShowApprove(false);
      await setApproved(true);
      setShowToastApproveSuccess(true);
    } else {
      await setShowApprove(false);
      setShowToastApproveError(true);
    }
  };

  return data !== null ? (
    <>
      <Card>
        <Card.Header>
          <Container className="block-fixed text-left">
            <Row>
              <Col xs={12} sm={12} md={4}>
                <Row>
                  <Col xs={1} sm={1} md={1} className="align-center">
                    <Link to={`/assets/${id}`} className="text-black">
                      <MdKeyboardArrowLeft size={32} className="text-link" />
                    </Link>
                  </Col>
                  <Col xs={3} sm={3} md={2}>
                    <Image
                      rounded={true}
                      thumbnail={true}
                      src={photoUrl(data.data.pro_pic)}
                    />
                  </Col>
                  <Col>
                    <p className="text-gray nopadding">SiamArt</p>
                    <p className="nopadding">
                      <Link to={`/collection/${data.data.pro_owner}`}>
                        #
                        {data.seller.name !== ""
                          ? data.seller.name
                          : showAddress(data.seller.name)}
                      </Link>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Card.Header>
      </Card>
      <Container className="block-fixed bg-content-main p-4">
        <Row className="mt-2 frm-sale-item">
          <Col md={5}>
            <h1>List item for sale</h1>
            <Form.Group className="mt-4">
              <Form.Label>Type</Form.Label>
              <Row className="frm-sale-type">
                <Col
                  md={6}
                  className={
                    type === 1
                      ? "border-right left active"
                      : "border-right right"
                  }
                  onClick={type !== 1 ? changeType : ""}
                >
                  <MdAttachMoney size={26} />
                  <div>Fixed Price</div>
                </Col>
                <Col
                  md={6}
                  className={type === 2 ? "active" : ""}
                  onClick={type !== 2 ? changeType : ""}
                >
                  <MdTimelapse size={26} />
                  <div>Timed Auction</div>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Form.Label>{type === 1 ? "Price" : "Starting price"}</Form.Label>
              <InputGroup className="mb-1">
                <InputGroup.Text className="pr-5 text-bold">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ETH.png`}
                    height={20}
                    alt="ETH"
                  />{" "}
                  {currency}
                </InputGroup.Text>
                <FormControl
                  size="lg"
                  type="number"
                  placeholder="Amount"
                  value={price}
                  min="0"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </InputGroup>
              <div className="text-right">
                {exchange !== null &&
                  "$" +
                    convertEthToUsd(
                      exchange.ETH.USD,
                      parseFloat(price === "" ? 0 : price).toFixed(4)
                    )}{" "}
                USD
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>Duration</Form.Label>
              <div>
                <TextField
                  label={false}
                  variant="outlined"
                  type="datetime-local"
                  sx={{ width: "100%" }}
                  value={durationDatetime}
                  onChange={(e) => setDurationDatetime(e.target.value)}
                  InputLabelProps={{
                    shrink: false,
                  }}
                  inputProps={{
                    min: moment(new Date()).format("Y-MM-DTHH:mm"),
                  }}
                />
              </div>
            </Form.Group>
            <hr />
            <Row>
              <Col>
                <h5 className="text-bold">Fees</h5>
                <p className="text-gray">Service Fee</p>
              </Col>
              <Col className="text-right text-gray">
                <MdInfoOutline size={24} />
                <p>2.5%</p>
              </Col>
            </Row>

            {approved === false ? (
              <Button
                disabled={showApprove === true ? true : false}
                size="lg"
                className="mt-4"
                onClick={handleApproveToken.bind(
                  null,
                  data.data.pro_minttoken,
                  data.data.id_pro
                )}
              >
                Approve
              </Button>
            ) : type === 1 ? (
              <Button
                disabled={submitButton === false ? true : false}
                size="lg"
                className="mt-4 ml-2"
                onClick={handleListToken.bind(
                  null,
                  data.data.pro_minttoken,
                  data.data.id_pro,
                  data.data.list_id
                )}
              >
                Complete listing
              </Button>
            ) : (
              <Button
                disabled={submitButton === false ? true : false}
                size="lg"
                className="mt-4 ml-2"
                onClick={handleAuctionCreate.bind(
                  null,
                  data.data.pro_minttoken,
                  data.data.id_pro,
                  data.data.list_id
                )}
              >
                Complete listing
              </Button>
            )}
          </Col>
          <Col md={{ offset: 1 }} className="asset-info mt-2">
            <h5>Preview</h5>
            <Row className="explore-result">
              <Col xs={12} sm={12} md={8} lg={6} className="asset-collection">
                <ExploreCard
                  provider={provider}
                  link={false}
                  type={type === 1 ? "buy" : "bid"}
                  data={data.data}
                  price={price === "" ? 0 : price}
                  durationDatetime={moment(durationDatetime).format(
                    "Y-MM-D HH:mm:ss"
                  )}
                  widthVal={12}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <DialogSaleComplete
          show={showComplete}
          handleClose={handleModalCompleteClose}
          checking={checking}
          data={data}
          price={price}
          exchange={exchange}
        />
        <DialogSaleView
          show={show}
          handleClose={closeRedirect}
          pid={id}
          photo={data.data.pro_pic}
        />

        <AlertMsg
          showToast={showToastSuccess}
          toggleShow={toggleShowSuccess}
          title="Your list item for sale was submitted successfully"
          msg="success"
        />
        <AlertMsg
          showToast={showToastError}
          toggleShow={toggleShowError}
          title="Your list item for sale was submitted fail!"
          msg="danger"
        />

        <AlertMsg
          showToast={showToastApproveSuccess}
          toggleShow={toggleShowApproveSuccess}
          title="Your approve item was submitted successfully"
          msg="success"
        />

        <AlertMsg
          showToast={showToastApproveError}
          toggleShow={toggleShowApproveError}
          title="Your approve item was submitted fail!"
          msg="danger"
        />

        <Modal
          show={showApprove}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>
              <Spinner animation="border" size="sm" />
              &nbsp;Approving
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please confirm and wait for confirmation on the blockchain.
          </Modal.Body>
        </Modal>

        <Modal
          show={showListing}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>
              <Spinner animation="border" size="sm" />
              &nbsp;Listing
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please confirm and wait for confirmation on the blockchain.
          </Modal.Body>
        </Modal>
      </Container>
    </>
  ) : (
    <LoadingOverlay active={true} spinner text="Loading..."></LoadingOverlay>
  );
};

const mapStateToProps = ({ productId, exchangeRate }) => {
  return { data: productId.result, exchange: exchangeRate.result };
};

export default connect(mapStateToProps, { getProductId })(AssetSell);
