import {
  PRODUCT_ID_REQUEST,
  PRODUCT_ID_REQUEST_FAIL,
  PRODUCT_ID_REQUEST_SUCCESS,
} from "./types";

export const getProductId = (payload) => {
  return {
    type: PRODUCT_ID_REQUEST,
    payload,
  };
};

export const getProductIdSuccess = (payload) => {
  return {
    type: PRODUCT_ID_REQUEST_SUCCESS,
    payload,
  };
};

export const getProductIdFail = (payload) => {
  return {
    type: PRODUCT_ID_REQUEST_FAIL,
    payload,
  };
};
