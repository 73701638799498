const WEBSERVICE_URL = process.env.REACT_APP_API_URL;
const EXTRACT_ERROR_MESSAGE = /(?<="message":")(.*?)(?=")/g;

function catchError(error) {
  console.error(error.message);

  // try to extract error message, otherwise return raw error
  let formatted_error;

  if (error.message.startsWith("invalid ENS name")) {
    formatted_error = "Missing or invalid parameter.";
  } else if (error.message.startsWith("invalid BigNumber string")) {
    formatted_error = "Invalid number parameter.";
  } else {
    try {
      let errors = JSON.stringify(error).match(EXTRACT_ERROR_MESSAGE);
      formatted_error = errors[errors.length - 1];
    } catch (e) {
      formatted_error = error.message;
    }
  }

  return formatted_error;
}

export async function verifyEmail() {
  let result;
  try {
    let token;
    try {
      token = await localStorage.getItem("_syart_member");
    } catch (err) {}

    await fetch(WEBSERVICE_URL + "/v1/emailverify", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    result = "SUCCESS_MSG";
  } catch (error) {
    result = catchError(error);
  }
  return result;
}

export async function UpdateAccountVerifyEmail(name, email = "") {
  let result;
  let res;
  let res2;

  try {
    let token;
    try {
      token = await localStorage.getItem("_syart_member");
    } catch (err) {}

    const dataPost =
      email !== ""
        ? JSON.stringify({
            name: name,
            email: email,
          })
        : JSON.stringify({
            name: name,
          });

    console.log("dataPost===>", dataPost);

    await fetch(`${WEBSERVICE_URL}/v1/account`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: dataPost,
    })
      .then((response) => response.json())
      .then((data) => {
        res = data;
      });

    console.log("res===>", res);

    if (res.success === true && res.data.name !== null) {
      await fetch(WEBSERVICE_URL + "/v1/emailverify", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          res2 = data;
        });

      console.log("res2===>", res2);

      result = "SUCCESS_MSG";
    } else {
      result = "ERROR_MSG";
    }
  } catch (error) {
    result = catchError(error);
  }
  return result;
}
