import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import axiosRetry from "axios-retry";
import { postFavSuccess } from "../actions";
import { POST_FAVORITE } from "../actions/types";
// import { authorizationHeader } from "../../utils/apiUtil";

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

const API_URL = process.env.REACT_APP_API_URL;

const dataRequest = async (payload) => {
  let token;
  try {
    token = await localStorage.getItem("_syart_member");
  } catch (err) {}

  return await axios
    .post(`${API_URL}/v1/productfav`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

// WORKER
function* getData({ payload }) {
  const response = yield call(dataRequest, payload);
  yield put(postFavSuccess(response));
}

// WATCHER
export function* getDataWatcher() {
  yield takeEvery(POST_FAVORITE, getData);
}

export default function* rootSaga() {
  yield all([fork(getDataWatcher)]);
}
