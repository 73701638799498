import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { HOMEPAGE_REQUEST } from "../actions/types";
import { getHomepageFail, getHomepageSuccess } from "../actions";
import axios from "axios";
import axiosRetry from "axios-retry";
// import { authorizationHeader } from "../../utils/apiUtil";

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

const API_URL = process.env.REACT_APP_API_URL;

const dataRequest = async () => {
  return await axios
    .get(`${API_URL}/homepage`)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

// WORKER
function* getData() {
  try {
    const response = yield call(dataRequest);

    if (response.data) {
      yield put(getHomepageSuccess(response.data));
    } else {
      yield put(getHomepageFail(response));
    }
  } catch (error) {
    yield put(getHomepageFail(error));
  }
}

// WATCHER
export function* getDataWatcher() {
  yield takeEvery(HOMEPAGE_REQUEST, getData);
}

export default function* rootSaga() {
  yield all([fork(getDataWatcher)]);
}
