import React from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";

const Redeemed = () => {
  return (
    <Container className="bg-content-main">
      <div>Redeemed</div>
    </Container>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {})(Redeemed);
