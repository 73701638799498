import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Container,
  OverlayTrigger,
  Tab,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { connect } from "react-redux";
import ProfilePhoto from "../components/ProfilePhoto";
import ProfileTabsCollected from "../components/ProfileTabsCollected";
import ProfileTabsCreated from "../components/ProfileTabsCreated";
import ProfileTabsFavorited from "../components/ProfileTabsFavorited";
import { showAddress } from "../services/content-functions";
import {
  MdEdit,
  MdFavoriteBorder,
  MdFormatPaint,
  MdPhotoFilter,
  MdSettings,
  MdShare,
  MdTimelapse,
} from "react-icons/md";
import {
  getAccount,
  getProductOwner,
  getProductFav,
  getProductBiding,
} from "../redux/actions";
import moment from "moment";
import { Link } from "wouter";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import AlertMsg from "../components/AlertMsg";
import { FaGripfire } from "react-icons/fa";
import ProfileTabsRedeem from "../components/ProfileTabsRedeem";
import DialogVerifyEmail from "../components/DialogVerifyEmail";

const Account = ({
  provider,
  signedInAddress,
  account,
  getAccount,
  getProductOwner,
  products,
  getProductFav,
  favList,
  getProductBiding,
  bidingList,
}) => {
  var dAddress = showAddress(signedInAddress);
  const [src, setSrc] = useState("");
  const [showVerify, setShowVerify] = useState(false);
  const [showToastSuccess, setShowToastSuccesss] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const toggleShowSuccess = () => setShowToastSuccesss(!showToastSuccess);
  const toggleShowError = () => setShowToastError(!showToastError);

  const onFileChange = async (e) => {
    var picture = e.target.files[0];
    var src = URL.createObjectURL(picture);

    setSrc(src);

    const formData = new FormData();
    formData.append("file", picture);

    let token;
    try {
      token = await localStorage.getItem("_syart_member");
    } catch (err) {}

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": `multipart/form-data`,
      },
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/accountprofile`,
        formData,
        options
      )
      .then((result) => {
        console.log(result);
        setShowToastSuccesss(true);
      })
      .catch((error) => {
        console.log(error);
        setShowToastError(true);
      });
  };

  const tooltipCopiedAddress = (props) => (
    <Tooltip {...props} id="tooltip-top">
      Copied to clipboard!
    </Tooltip>
  );

  const filteredAccount = useCallback(
    async () => await getAccount(),
    [getAccount]
  );
  useEffect(() => filteredAccount(), [filteredAccount]);

  const filteredProductOwner = useCallback(
    async () => await getProductOwner(),
    [getProductOwner]
  );
  useEffect(() => filteredProductOwner(), [filteredProductOwner]);

  const filteredFav = useCallback(
    async () => await getProductFav(),
    [getProductFav]
  );
  useEffect(() => filteredFav(), [filteredFav]);

  const filteredBiding = useCallback(
    async () => await getProductBiding(),
    [getProductBiding]
  );
  useEffect(() => filteredBiding(), [filteredBiding]);

  useEffect(() => {
    if (account.result !== null) {
      // console.log(account.result);
      setShowVerify(account.result.is_active === 1 ? true : false);
    }
  }, [account.result]);

  return account.result !== null &&
    products.result !== null &&
    favList.result !== null &&
    bidingList.result !== null ? (
    <Container className="bg-content-main-white pt-0">
      <div className="block-account">
        <div className="cover"></div>
        <div className="account-setting pt-2">
          <ButtonToolbar aria-label="Toolbar with button groups">
            <ButtonGroup className="me-2" aria-label="First group">
              <OverlayTrigger
                key="share"
                placement="top"
                overlay={<Tooltip id="tooltip-top">Share</Tooltip>}
              >
                <Button variant="outline-secondary">
                  <MdShare size={24} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                key="setting"
                placement="top"
                overlay={<Tooltip id="tooltip-top">Setting</Tooltip>}
              >
                <Link to="/account/settings">
                  <Button variant="outline-secondary">
                    <MdSettings size={24} />
                  </Button>
                </Link>
              </OverlayTrigger>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
        <div>
          <div className="profile-center text-center">
            <ProfilePhoto
              path={src !== "" ? src : account.result.profile_photo_path}
              type={src !== "" ? "blob" : ""}
            />

            <label htmlFor="file-upload" className="file-upload">
              <div className="bg-edit">
                <MdEdit size={26} />
                <input
                  id="file-upload"
                  type="file"
                  accept="image/x-png, image/jpeg"
                  onChange={onFileChange}
                />
              </div>
            </label>
          </div>
          <div className="account-center text-center">
            <h1>
              {account.result.name === null ? "Unnamed" : account.result.name}
            </h1>
            <OverlayTrigger
              key="hover"
              placement="top"
              overlay={<Tooltip id="tooltip-top">Copy</Tooltip>}
            >
              <CopyToClipboard text={signedInAddress}>
                <span className="text-secondary">
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    trigger="click"
                    rootClose={true}
                    onHide
                    rootCloseEvent="mousedown"
                    overlay={tooltipCopiedAddress}
                  >
                    <h5 className="text-gray pointer address-area">
                      {dAddress}
                    </h5>
                  </OverlayTrigger>
                </span>
              </CopyToClipboard>
            </OverlayTrigger>

            <h5 className="text-gray">
              Joined {moment(account.result.created_at).format("MMMM YYYY")}
            </h5>
          </div>
        </div>

        <div className="account-content">
          <Tabs defaultActiveKey="collected" id="uncontrolled-tab-example">
            <Tab
              eventKey="collected"
              title={
                <>
                  <MdPhotoFilter size={24} /> Collected{" "}
                  <span className="text-small">
                    {"(" +
                      products.result.filter(
                        (x) =>
                          x.pro_status === 7 ||
                          x.pro_status === 15 ||
                          x.pro_status === 16 ||
                          x.pro_status === 17 ||
                          x.pro_status === 18
                      ).length +
                      ")"}
                  </span>
                </>
              }
            >
              <ProfileTabsCollected
                provider={provider}
                data={products.result.filter(
                  (x) =>
                    x.pro_status === 7 ||
                    x.pro_status === 15 ||
                    x.pro_status === 16 ||
                    x.pro_status === 17 ||
                    x.pro_status === 18
                )}
              />
            </Tab>
            <Tab
              eventKey="created"
              title={
                <>
                  <MdFormatPaint size={24} /> Created{" "}
                  <span className="text-small">
                    {"(" +
                      products.result.filter(
                        (x) =>
                          x.pro_status >= 1 &&
                          x.pro_status <= 6 &&
                          x.pro_status !== 15 &&
                          x.pro_status !== 16
                      ).length +
                      ")"}
                  </span>
                </>
              }
            >
              <ProfileTabsCreated
                provider={provider}
                signedInAddress={signedInAddress}
                data={products.result.filter(
                  (x) =>
                    x.pro_status >= 1 &&
                    x.pro_status <= 6 &&
                    x.pro_status !== 15 &&
                    x.pro_status !== 16
                )}
              />
            </Tab>
            <Tab
              eventKey="favorited"
              title={
                <>
                  <MdFavoriteBorder size={24} /> Favorited{" "}
                  <span className="text-small">
                    {"(" + favList.result.length + ")"}
                  </span>
                </>
              }
            >
              <ProfileTabsFavorited provider={provider} data={favList.result} />
            </Tab>
            <Tab
              eventKey="biding"
              title={
                <>
                  <MdTimelapse size={24} /> Biding{" "}
                  <span className="text-small">
                    {"(" + bidingList.result.length + ")"}
                  </span>
                </>
              }
            >
              <ProfileTabsCollected
                provider={provider}
                signedInAddress={signedInAddress}
                data={bidingList.result}
              />
            </Tab>
            <Tab
              eventKey="redeemed"
              title={
                <>
                  <FaGripfire size={24} /> Redeemed{" "}
                  <span className="text-small">
                    {"(" +
                      products.result.filter(
                        (x) => x.pro_status === 8 || x.pro_status === 9
                      ).length +
                      ")"}
                  </span>
                </>
              }
            >
              <ProfileTabsRedeem
                provider={provider}
                signedInAddress={signedInAddress}
                data={products.result.filter(
                  (x) => x.pro_status === 8 || x.pro_status === 9
                )}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
      <AlertMsg
        showToast={showToastSuccess}
        toggleShow={toggleShowSuccess}
        title="Your upload profile successfully!"
        msg="success"
      />{" "}
      <AlertMsg
        showToast={showToastError}
        toggleShow={toggleShowError}
        title="Your upload profile fail!"
        msg="danger"
      />
      <DialogVerifyEmail showVerify={showVerify} />
    </Container>
  ) : (
    <LoadingOverlay active={true} spinner text="Loading..."></LoadingOverlay>
  );
};

const mapStateToProps = ({
  account,
  productOwner,
  productFavorite,
  productBiding,
}) => {
  return {
    account: account,
    products: productOwner,
    favList: productFavorite,
    bidingList: productBiding,
  };
};

export default connect(mapStateToProps, {
  getAccount,
  getProductOwner,
  getProductFav,
  getProductBiding,
})(Account);
