import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_DATA } from "../actions/types";
import { getDataFail, getDataSuccess } from "../actions";
import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

// const API_URL = "https://test.com/api/v1";

const dataRequest = async () => {
  // return await axios
  //   .get(`${API_URL}/data`, {
  //     headers: {
  //       "Content-type": "application/json",
  //     },
  //   })
  //   .then((response) => response.data)
  //   .catch((error) => {
  //     throw error;
  //   });
  return { status: "OK", value: Math.random() };
};

// WORKER
function* getData() {
  try {
    const response = yield call(dataRequest);

    if (response.status === "OK") {
      yield put(getDataSuccess(response));
    } else {
      yield put(getDataFail(response));
    }
  } catch (error) {
    yield put(getDataFail(error));
  }
}

// WATCHER
export function* getDataWatcher() {
  yield takeEvery(GET_DATA, getData);
}

export default function* rootSaga() {
  yield all([fork(getDataWatcher)]);
}
