import { GET_PROFILE, GET_PROFILE_FAIL, GET_PROFILE_SUCCESS } from "./types";

export const getProfile = (payload) => {
  return {
    type: GET_PROFILE,
    payload,
  };
};

export const getProfileSuccess = (payload) => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload,
  };
};

export const getProfileFail = (payload) => {
  return {
    type: GET_PROFILE_FAIL,
    payload,
  };
};
