import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "wouter";
import { getAccount } from "../redux/actions";
import { verifyEmail } from "../services/api/verify-email";

const VerifyEmailSuccess = ({ account, getAccount }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAccount();
  }, [getAccount]);

  const handleVerifyEmail = async () => {
    setLoading(true);
    const result = await verifyEmail();
    if (result === "SUCCESS_MSG") {
      // console.log(result);
      setShowModal(true);
    }
  };

  return (
    account !== null && (
      <Container className="bg-content-main block-fixed">
        {account.is_active === 2 ? (
          <div className="block-account-detail text-center pt-5">
            <h1>Verified</h1>
            <img
              src={`${process.env.PUBLIC_URL}/icons/circle_correct_mark_success_tick_icon.png`}
              alt="circle correct"
            />
            <h3>Success</h3>
            <p>You have successfully verified email address.</p>

            <Link to="/account">
              <Button className="width-100">OK</Button>
            </Link>
          </div>
        ) : (
          <div className="block-account-detail text-center pt-5">
            <h1>Verified</h1>
            <img
              src={`${process.env.PUBLIC_URL}/icons/error_icon.png`}
              alt="error icon"
            />
            <h4 className="mt-2">Failed!</h4>
            <p>You have failed verified email address.</p>

            <Button
              className="width-100"
              onClick={handleVerifyEmail}
              disabled={loading === true ? true : false}
            >
              {loading === true && <Spinner animation="border" size="sm" />}
              Resend verification email
            </Button>
          </div>
        )}

        <Modal
          show={showModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Plase verify your account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <img
                src={`${process.env.PUBLIC_URL}/icons/email_newsletter_subscription_icon.png`}
                alt="circle correct"
              />
            </div>
            <p className="mt-2">
              We have send a verification mail to {account.email} Plase activate
              your account with the link in this mail.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="width-100"
              onClick={() => {
                setShowModal(false);
                setLoading(false);
                getAccount();
              }}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    )
  );
};

const mapStateToProps = ({ account }) => {
  return { account: account.result };
};

export default connect(mapStateToProps, { getAccount })(VerifyEmailSuccess);
