import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Container, Button, Carousel, Card } from "react-bootstrap";
// import sliderTemplate1 from "../assets/sliderTemplate1.jpg";
// import iconBoat from "../assets/icon-boat.png";
// import iconDocument from "../assets/icon-document.png";
// import iconGlass from "../assets/icon-glass.png";
// import iconShop from "../assets/icon-shop.png";
// import iconCircle from "../assets/icon-circle.png";
import exampleImage from "../assets/example-image.jpg";
import buddha from "../assets/buddha.jpg";
// import iconExplore from "../assets/icon-explore.png";
import { getProduct } from "../redux/actions";
import ReactHtmlParser from "react-html-parser";
import ExploreCard from "../components/ExploreCard";
import { Link } from "wouter";
import { BsFacebook, BsTwitter, BsLinkedin, BsInstagram } from "react-icons/bs";
import { MdLocationOn, MdMail, MdOutlineCall } from "react-icons/md";

const Home = ({ provider, data, products, getProduct }) => {
  const getProductData = useCallback(
    async () => await getProduct(),
    [getProduct]
  );
  useEffect(() => getProductData(), [getProductData]);

  return (
    data !== null &&
    products !== null && (
      <div
        className="bg-home"
        style={{
          background: `url(${process.env.REACT_APP_IMAGE_URL}${data.bgweb.logo_menu}`,
        }}
      >
        <div md={6} className="bg-home-gradient w-100 d-none d-sm-block"></div>
        <Container className="block-fixed p-0">
          <Carousel controls={false}>
            {data.content_1.map((x, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={process.env.REACT_APP_IMAGE_URL + x.image_background}
                  alt={`slide ${index}`}
                />
                <Carousel.Caption>
                  <Col md={6}>
                    <h1>{x.header}</h1>
                    <p>{x.description}</p>
                    <Link to={x.link_button}>
                      <Button
                        style={{
                          backgroundColor: x.colour_text_button,
                          border: `1px solid ${x.colour_text_button}`,
                        }}
                      >
                        <img
                          src={process.env.REACT_APP_IMAGE_URL + x.icon_button}
                          className="button-icon"
                          alt="icon button"
                        />
                        {x.text_button}
                      </Button>
                    </Link>
                  </Col>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
          <div className="bg-header">
            <Row>
              <Col className="text-left align-self-center">
                <h3>{data.content_2[0].header_1}</h3>
                <p className="mb-0">{data.content_2[0].header_3}</p>
              </Col>
              <Col className="text-right align-self-center">
                <h2 className="mb-0">{data.content_2[0].header_2}</h2>
              </Col>
            </Row>
          </div>
          <div
            className="bg-content-1"
            style={{
              background: `url(${process.env.REACT_APP_IMAGE_URL}${data.content_3.bg.logo_menu}`,
            }}
          >
            <Row>
              <Col md={5} className="text-left align-self-center mb-3">
                <h1 className="text-light">
                  {data.content_3.part_1[0].header}
                </h1>
                <p className="text-light">
                  {data.content_3.part_1[0].description}
                </p>
                <Row>
                  <Col md={4}>
                    <img
                      src={
                        data.content_3.part_1[0].image_1 === ""
                          ? exampleImage
                          : process.env.REACT_APP_IMAGE_URL +
                            data.content_3.part_1[0].image_1
                      }
                      className="w-100 mb-2"
                      alt={exampleImage}
                    />
                  </Col>
                  <Col md={4}>
                    <img
                      src={
                        data.content_3.part_1[0].image_2 === ""
                          ? exampleImage
                          : process.env.REACT_APP_IMAGE_URL +
                            data.content_3.part_1[0].image_2
                      }
                      className="w-100 mb-2"
                      alt={exampleImage}
                    />
                  </Col>
                  <Col md={4}>
                    <img
                      src={
                        data.content_3.part_1[0].image_3 === ""
                          ? exampleImage
                          : process.env.REACT_APP_IMAGE_URL +
                            data.content_3.part_1[0].image_3
                      }
                      className="w-100 mb-2"
                      alt={exampleImage}
                    />
                  </Col>
                </Row>
                <Link
                  to={
                    process.env.REACT_APP_IMAGE_URL +
                    data.content_3.part_1[0].link_button
                  }
                >
                  <Button
                    className="btn-brown mt-2"
                    style={{
                      backgroundColor:
                        data.content_3.part_1[0].colour_text_button,
                      border: `1px solid ${data.content_3.part_1[0].colour_text_button}`,
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_IMAGE_URL +
                        data.content_3.part_1[0].icon_button
                      }
                      className="button-icon"
                      alt="icon glass"
                    />
                    {data.content_3.part_1[0].text_button}
                  </Button>
                </Link>
              </Col>
              <Col md={{ span: 6, offset: 1 }}>
                <Card>
                  <Card.Body>
                    <Card.Title className="card-content1-header">
                      {data.content_3.part_2[0].header}
                    </Card.Title>
                    <Card.Text className="card-content1-second-header ">
                      {data.content_3.part_2[0].description}
                    </Card.Text>
                    <div className="dropdown-divider"></div>
                    <Card.Text className="card-content1-text">
                      {ReactHtmlParser(data.content_3.part_2[0].detail)}
                    </Card.Text>
                    <div className="text-center">
                      <Link
                        to={
                          process.env.REACT_APP_IMAGE_URL +
                          data.content_3.part_2[0].link_button
                        }
                      >
                        <Button variant="dark">
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              data.content_3.part_2[0].icon_button
                            }
                            className="button-icon"
                            alt="icon document"
                          />
                          {data.content_3.part_2[0].text_button}
                        </Button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <div
            className="bg-content-2 text-center"
            style={{
              background: `url(${process.env.REACT_APP_IMAGE_URL}${data.dontmiss.image_background}`,
            }}
          >
            <h1>{data.dontmiss.header}</h1>
            <p className="text-grey header">{data.dontmiss.description}</p>
            <Row className="mt-4">
              <Col md={{ offset: 1, span: 4 }}>
                <img src={buddha} className="w-100 rounded" alt={buddha} />
              </Col>
              <Col md={{ offset: 1, span: 5 }} className="text-left mt-3">
                <h2 className="danger">00:29:11 Remainings</h2>
                <h2>Isara Budda Dirt</h2>
                <Row className="text-left pl-3 pr-3">
                  <p className="tag">Tag</p>
                  <p className="tag">Tag</p>
                  <p className="tag">Tag</p>
                </Row>
                <p className="mt-2 text-grey">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque varius massa quis mattis sagittis. Mauris nulla lorem,
                  interdum vitae tortor in, placerat finibus diam.
                </p>
                <Button className="btn-danger">
                  <img
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      data.dontmiss.icon_button
                    }
                    className="button-icon"
                    alt="icon shop"
                  />
                  {data.dontmiss.colour_text_button}
                </Button>
              </Col>
            </Row>
          </div>
          <div
            className="bg-content-3 text-center"
            style={{
              background: `url(${process.env.REACT_APP_IMAGE_URL}${data.Newlymint.image_background}`,
            }}
          >
            <Row>
              <Col>
                <h1 className="text-left">{data.Newlymint.header}</h1>
              </Col>
              <Col className="align-self-center">
                <p className="text-right header mb-0">
                  {data.Newlymint.description}
                </p>
              </Col>
            </Row>
            <Row className="mt-3 explore-result">
              {products.length > 0 &&
                products
                  .slice(0, 8)
                  .map((x, index) => (
                    <ExploreCard
                      provider={provider}
                      key={index}
                      type={x.fk_sell_type === 1 ? "buy" : "bid"}
                      data={x}
                    />
                  ))}
            </Row>
            <Col className="mt-3" md={{ offset: 3, span: 6 }}>
              <h1>
                {products.length}+ more Items in our marketplaceare waiting to
                be discovered
              </h1>
              <Link to={data.Newlymint.link_button}>
                <Button className="btn-green mt-2 mb-4">
                  <img
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      data.Newlymint.icon_button
                    }
                    className="button-icon"
                    alt="icon newly mint"
                  />
                  {data.Newlymint.text_button}
                </Button>
              </Link>
            </Col>
          </div>

          {data.Howitworks !== null && (
            <Carousel controls={false} indicators={false}>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    data.Howitworks.image_background
                  }
                  alt="how it work"
                />
                <Carousel.Caption>
                  <Col md={6}>
                    <h1>{data.Howitworks.header}</h1>
                    <p>{data.Howitworks.description}</p>
                    <Link to={data.Howitworks.link_button}>
                      <Button
                        style={{
                          backgroundColor: data.Howitworks.colour_text_button,
                          border: `1px solid ${data.Howitworks.colour_text_button}`,
                        }}
                      >
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            data.Howitworks.icon_button
                          }
                          className="button-icon"
                          alt="icon how it work"
                        />
                        {data.Howitworks.text_button}
                      </Button>
                    </Link>
                  </Col>
                  {data.Howitworks.photo !== "" && (
                    <Col md={{ offset: 2, span: 4 }} className="text-center">
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          data.Howitworks.photo
                        }
                        height={400}
                        alt={data.Howitworks.photo}
                      />
                    </Col>
                  )}
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          )}

          {data.Minting !== null && (
            <Carousel controls={false} indicators={false}>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    data.Minting.image_background
                  }
                  alt="mining"
                />
                <Carousel.Caption>
                  <Col md={6}>
                    <h1>{data.Minting.header}</h1>
                    <p>{data.Minting.description}</p>
                    <Link to={data.Minting.link_button}>
                      <Button
                        style={{
                          backgroundColor: data.Minting.colour_text_button,
                          border: `1px solid ${data.Minting.colour_text_button}`,
                        }}
                      >
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            data.Minting.icon_button
                          }
                          className="button-icon"
                          alt="icon mining"
                        />
                        {data.Minting.text_button}
                      </Button>
                    </Link>
                  </Col>
                  {data.Minting.photo !== "" && (
                    <Col md={{ offset: 2, span: 4 }} className="text-center">
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL + data.Minting.photo
                        }
                        height={400}
                        alt={data.Minting.photo}
                      />
                    </Col>
                  )}
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          )}

          {data.Trading !== null && (
            <Carousel controls={false} indicators={false}>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    data.Trading.image_background
                  }
                  alt="trading"
                />
                <Carousel.Caption>
                  <Col md={6}>
                    <h1>{data.Trading.header}</h1>
                    <p>{data.Trading.description}</p>
                    <Link to={data.Trading.link_button}>
                      <Button
                        style={{
                          backgroundColor: data.Trading.colour_text_button,
                          border: `1px solid ${data.Trading.colour_text_button}`,
                        }}
                      >
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            data.Trading.icon_button
                          }
                          className="button-icon"
                          alt="icon trading"
                        />
                        {data.Trading.text_button}
                      </Button>
                    </Link>
                  </Col>
                  {data.Trading.photo !== "" && (
                    <Col md={{ offset: 2, span: 4 }} className="text-center">
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL + data.Trading.photo
                        }
                        height={400}
                        alt={data.Trading.photo}
                      />
                    </Col>
                  )}
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          )}

          {data.Redemption !== null && (
            <Carousel controls={false} indicators={false}>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    data.Redemption.image_background
                  }
                  alt="redemption"
                />
                <Carousel.Caption>
                  <Col md={6}>
                    <h1>{data.Redemption.header}</h1>
                    <p>{data.Redemption.description}</p>
                    <Link to={data.Redemption.link_button}>
                      <Button
                        style={{
                          backgroundColor: data.Redemption.colour_text_button,
                          border: `1px solid ${data.Redemption.colour_text_button}`,
                        }}
                      >
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            data.Redemption.icon_button
                          }
                          className="button-icon"
                          alt="icon redemption"
                        />
                        {data.Redemption.text_button}
                      </Button>
                    </Link>
                  </Col>
                  {data.Redemption.photo !== "" && (
                    <Col md={{ offset: 2, span: 4 }} className="text-center">
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          data.Redemption.photo
                        }
                        height={400}
                        alt={data.Redemption.photo}
                      />
                    </Col>
                  )}
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          )}

          {/* <div className="bg-content-4 text-center">
            <Col className="mt-3 mb-3" md={{ offset: 3, span: 6 }}>
              <h2 className="text-light">
                ROADMAP & PROFITTO
                <br /> TO INVESTORS
              </h2>
              <p className="text-light mt-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                varius massa quis mattis sagittis. Mauris nulla lorem, interdum
                vitae tortor in, placerat finibus diam.
              </p>
            </Col>
            <Row className="m-3">
              <Col
                md={{ offset: 1, span: 2 }}
                className="align-self-center p-3"
              >
                <img
                  src={exampleImage}
                  className="rounded-circle w-100"
                  alt={exampleImage}
                />
              </Col>
              <Col md={8} className="align-self-center">
                <p className="text-light text-left">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque varius massa quis mattis sagittis. Mauris nulla lorem,
                  interdum vitae tortor in, placerat finibus diam. Sed laoreet
                  leo in pulvinar accumsan. Duis quis ex sed tellus vehicula
                  pharetra at ut quam. Aenean feugiat nunc eget turpis
                  imperdiet,
                </p>
              </Col>
            </Row>
            <Row className="m-3">
              <Col
                md={{ offset: 1, span: 2 }}
                className="align-self-center p-3"
              >
                <img
                  src={exampleImage}
                  className="rounded-circle w-100"
                  alt={exampleImage}
                />
              </Col>
              <Col md={8} className="align-self-center">
                <p className="text-light text-left">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque varius massa quis mattis sagittis. Mauris nulla lorem,
                  interdum vitae tortor in, placerat finibus diam. Sed laoreet
                  leo in pulvinar accumsan. Duis quis ex sed tellus vehicula
                  pharetra at ut quam. Aenean feugiat nunc eget turpis
                  imperdiet,
                </p>
              </Col>
            </Row>
            <Row className="m-3">
              <Col
                md={{ offset: 1, span: 2 }}
                className="align-self-center p-3"
              >
                <img
                  src={exampleImage}
                  className="rounded-circle w-100"
                  alt={exampleImage}
                />
              </Col>
              <Col md={8} className="align-self-center">
                <p className="text-light text-left">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque varius massa quis mattis sagittis. Mauris nulla lorem,
                  interdum vitae tortor in, placerat finibus diam. Sed laoreet
                  leo in pulvinar accumsan. Duis quis ex sed tellus vehicula
                  pharetra at ut quam. Aenean feugiat nunc eget turpis
                  imperdiet,
                </p>
              </Col>
            </Row>
            <Row className="m-3">
              <Col
                md={{ offset: 1, span: 2 }}
                className="align-self-center p-3"
              >
                <img
                  src={exampleImage}
                  className="rounded-circle w-100"
                  alt={exampleImage}
                />
              </Col>
              <Col md={8} className="align-self-center">
                <p className="text-light text-left">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque varius massa quis mattis sagittis. Mauris nulla lorem,
                  interdum vitae tortor in, placerat finibus diam. Sed laoreet
                  leo in pulvinar accumsan. Duis quis ex sed tellus vehicula
                  pharetra at ut quam. Aenean feugiat nunc eget turpis
                  imperdiet,
                </p>
              </Col>
            </Row>
            <Row className="m-3">
              <Col
                md={{ offset: 1, span: 2 }}
                className="align-self-center p-3"
              >
                <img
                  src={exampleImage}
                  className="rounded-circle w-100"
                  alt={exampleImage}
                />
              </Col>
              <Col md={8} className="align-self-center">
                <p className="text-light text-left">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque varius massa quis mattis sagittis. Mauris nulla lorem,
                  interdum vitae tortor in, placerat finibus diam. Sed laoreet
                  leo in pulvinar accumsan. Duis quis ex sed tellus vehicula
                  pharetra at ut quam. Aenean feugiat nunc eget turpis
                  imperdiet,
                </p>
              </Col>
            </Row>
            <p className="text-light p-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              varius massa quis mattis sagittis. Mauris nulla lorem, interdum
              vitae tortor in, placerat finibus diam. Sed laoreet leo in
              pulvinar accumsan. Duis quis ex sed tellus vehicula pharetra at ut
              quam. Aenean feugiat nunc eget turpis imperdiet,
            </p>
          </div> */}
          {/* <div className="bg-content-5 text-center">
            <h2 className="text-light">MINT your collections</h2>
            <Row className="w-75 p-4 m-auto">
              <Col md={3}>
                <img
                  src={exampleImage}
                  className="rounded-circle w-100 mb-3"
                  alt={exampleImage}
                />
              </Col>
              <Col md={3}>
                <img
                  src={exampleImage}
                  className="rounded-circle w-100 mb-3"
                  alt={exampleImage}
                />
              </Col>
              <Col md={3}>
                <img
                  src={exampleImage}
                  className="rounded-circle w-100 mb-3"
                  alt={exampleImage}
                />
              </Col>
              <Col md={3}>
                <img
                  src={exampleImage}
                  className="rounded-circle w-100 mb-3"
                  alt={exampleImage}
                />
              </Col>
            </Row>
            <p className="text-light mt-3 p-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              varius massa quis mattis sagittis. Mauris nulla lorem, interdum
              vitae tortor in, placerat finibus diam.
            </p>
            <Button className="btn-danger">
              <img src={iconCircle} className="button-icon" alt={iconCircle} />
              MINT Yours Now!
            </Button>
          </div> */}
          {/* <div className="bg-content-6 text-center pb-5">
            <h2>How to redeem your product</h2>
          </div>
          <div className="bg-content-7 text-center">
            <h2>TECHNOLOGY, TEAM & MORE</h2>
          </div> */}

          <footer
            className="text-center text-lg-start text-white"
            style={{ backgroundColor: data.Footer.bg }}
          >
            <div className="container p-5 pb-0">
              <section className>
                <div className="row">
                  <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                    <h5 className="pb-2">{data.Footer.header_1}</h5>
                    <p>{data.Footer.content_1.text_detail}</p>

                    <ul className="list-unstyled mb-0">
                      <li>
                        <MdLocationOn /> {data.Footer.content_1.address_detail}
                      </li>
                      <li>
                        <MdOutlineCall /> {data.Footer.content_1.phone_detail}
                      </li>
                      <li>
                        <MdMail /> {data.Footer.content_1.mail_detail}
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                    <h5 className="pb-2">{data.Footer.header_2}</h5>
                    <ul className="list-unstyled mb-0">
                      {data.Footer.content_2.map((x, index) => (
                        <li key={index}>
                          <Link to={x.links_url} className="text-white">
                            {x.name_links}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                    <h5 className="pb-2">{data.Footer.header_3}</h5>
                    <ul className="list-unstyled mb-0">
                      {data.Footer.content_3.map((x, index) => (
                        <li key={index}>
                          <Link to={x.links_url} className="text-white">
                            {x.name_links}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                    <h5 className="pb-2">{data.Footer.header_4}</h5>
                    <ul className="list-unstyled mb-0">
                      {data.Footer.content_4.map((x, index) => (
                        <li key={index}>
                          <Link to={x.links_url} className="text-white">
                            {x.name_links}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </section>
              <hr className="mb-2" />
              <section className="pb-2 text-center">
                <p>Copyright ©2022 | siamart.com</p>
                <a className="btn btn-outline-light btn-floating m-1" href="#">
                  <BsFacebook />
                </a>
                <a className="btn btn-outline-light btn-floating m-1" href="#">
                  <BsTwitter />
                </a>
                <a className="btn btn-outline-light btn-floating m-1" href="#">
                  <BsInstagram />
                </a>
                <a href="#" className="btn btn-outline-light btn-floating m-1">
                  <BsLinkedin />
                </a>
              </section>
            </div>
          </footer>
        </Container>
      </div>
    )
  );
};

const mapStateToProps = ({ authen, homepage, product }) => {
  return {
    auth: authen.result,
    data: homepage.result,
    products: product.result,
  };
};

export default connect(mapStateToProps, { getProduct })(Home);
