import { BID_LIST_FAIL, BID_LIST_REQUEST, BID_LIST_SUCCESS } from "./types";

export const getProductBiding = () => {
  return {
    type: BID_LIST_REQUEST,
  };
};

export const getProductBidingSuccess = (payload) => {
  return {
    type: BID_LIST_SUCCESS,
    payload,
  };
};

export const getProductBidingFail = (payload) => {
  return {
    type: BID_LIST_FAIL,
    payload,
  };
};
