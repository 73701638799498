import React, { useRef } from "react";
import NavigationBar from "./components/NavigationBar";
import Dashboard from "./pages/Dashboard";
import Minting from "./pages/Minting";
import Burning from "./pages/Burning";
import useWeb3Modal from "./hooks/useWeb3Modal";
import "react-sliding-pane/dist/react-sliding-pane.css";

import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import { Route, Switch, useLocation } from "wouter";
import Sign from "./pages/Sign";
import Test from "./pages/Test";
import Home from "./pages/Home";
import Account from "./pages/Account";
import Assets from "./pages/Assets";
import AssetDetail from "./pages/AssetDetail";
import AssetSell from "./pages/AssetSell";
import AccountSettings from "./pages/AccountSettings";
import Redeemed from "./pages/Redeemed";
import AlphaNFT from "./pages/AlphaNFT";
import Faq from "./pages/Faq";
import Collection from "./pages/Collection";
import AssetClaim from "./pages/AssetClaim";
import VerifyEmail from "./pages/VerifyEmail";

function App() {
  const [
    provider,
    balance,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signedInAddress,
    roles,
    limitedMode,
  ] = useWeb3Modal();
  const [location] = useLocation();
  const dashboardRef = useRef();
  return (
    <>
      <NavigationBar
        provider={provider}
        balance={balance}
        loadWeb3Modal={loadWeb3Modal}
        logoutOfWeb3Modal={logoutOfWeb3Modal}
        signedInAddress={signedInAddress}
        roles={roles}
        limitedMode={limitedMode}
      />

      <Container fluid>
        <Tab.Container defaultActiveKey={location.substring(1) || "dashboard"}>
          <Tab.Content animation="true">
            <Switch>
              <Route path="/">
                <Home provider={provider} />
              </Route>
              <Route path="/redeemed">
                <Redeemed />
              </Route>
              <Route path="/alpha-nft">
                <AlphaNFT />
              </Route>
              <Route path="/faq">
                <Faq />
              </Route>
              <Route path="/assets">
                <Assets provider={provider} />
              </Route>
              <Route path="/verify-email">
                <VerifyEmail />
              </Route>
              <Route path="/collection/:address">
                {(params) => (
                  <Collection
                    params={params}
                    provider={provider}
                    loadWeb3Modal={loadWeb3Modal}
                  />
                )}
              </Route>
              <Route path="/assets/:id">
                {(params) => (
                  <AssetDetail
                    params={params}
                    provider={provider}
                    loadWeb3Modal={loadWeb3Modal}
                  />
                )}
              </Route>
              <Route path="/test">
                <Test />
              </Route>
              {provider && (
                <>
                  <Route path="/sign">
                    <Sign
                      provider={provider}
                      signedInAddress={signedInAddress}
                    />
                  </Route>
                  <Route path="/dashboard">
                    <Dashboard
                      ref={dashboardRef}
                      provider={provider}
                      signedInAddress={signedInAddress}
                    />
                  </Route>
                  <Route path="/minting">
                    <Minting
                      provider={provider}
                      signedInAddress={signedInAddress}
                    />
                  </Route>
                  <Route path="/burning">
                    <Burning provider={provider} />
                  </Route>
                  <Route path="/account">
                    <Account
                      provider={provider}
                      signedInAddress={signedInAddress}
                    />
                  </Route>
                  <Route path="/account/settings">
                    <AccountSettings signedInAddress={signedInAddress} />
                  </Route>
                  <Route path="/assets/:id/sell">
                    {(params) => (
                      <AssetSell params={params} provider={provider} />
                    )}
                  </Route>
                  <Route path="/assets/:id/claim">
                    {(params) => (
                      <AssetClaim params={params} provider={provider} />
                    )}
                  </Route>
                  <Route component={Assets} />
                </>
              )}{" "}
            </Switch>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </>
  );
}

export default App;
