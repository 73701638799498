import moment from "moment";

export function showAddress(addr) {
  const prefix = addr.substring(0, 6);
  const suffix = addr.substring(addr.length - 4);
  return prefix + "..." + suffix;
}

export function addressName(addr) {
  const prefix = addr.substring(3, 9);
  return prefix;
}

export function photoUrl(name) {
  const url = process.env.REACT_APP_IMAGE_URL;
  return url + name;
}

export function diffDate(dateTimeEnd) {
  const startDate = moment(moment(new Date()));
  const timeEnd = moment(dateTimeEnd);
  const diff = timeEnd.diff(startDate);
  const diffDuration = moment.duration(diff);
  if (diffDuration.days() > 0) {
    return diffDuration.days() + " days";
  } else if (diffDuration.hours() > 0) {
    return diffDuration.hours() + " hours";
  } else if (diffDuration.minutes() > 0) {
    return diffDuration.minutes() + " minutes";
  } else {
    return "";
  }
}

export function diffDateStart(dateTimeStart) {
  const startDate = moment(dateTimeStart);
  const timeEnd = moment(new Date());
  const diff = timeEnd.diff(startDate);
  const diffDuration = moment.duration(diff);
  if (diffDuration.days() > 0) {
    return diffDuration.days() + " days ago";
  } else if (diffDuration.hours() > 0) {
    return diffDuration.hours() + " hours ago";
  } else if (diffDuration.minutes() > 0) {
    return diffDuration.minutes() + " minutes ago";
  } else if (diffDuration.seconds() > 0) {
    return "just now";
  } else {
    return "";
  }
}

export function convertEthToUsd(rate, eth) {
  return ((parseFloat(eth) / 1) * rate).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
