export const GET_DATA = "GET_DATA";
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS";
export const GET_DATA_FAIL = "GET_DATA_FAIL";

export const AUTHEN = "AUTHEN";
export const AUTHEN_SUCCESS = "AUTHEN_SUCCESS";
export const AUTHEN_FAIL = "AUTHEN_FAIL";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const PRODUCT_REQUEST = "PRODUCT_REQUEST";
export const PRODUCT_REQUEST_SUCCESS = "PRODUCT_REQUEST_SUCCESS";
export const PRODUCT_REQUEST_FAIL = "PRODUCT_REQUEST_FAIL";

export const PRODUCT_ID_REQUEST = "PRODUCT_ID_REQUEST";
export const PRODUCT_ID_REQUEST_SUCCESS = "PRODUCT_ID_REQUEST_SUCCESS";
export const PRODUCT_ID_REQUEST_FAIL = "PRODUCT_ID_REQUEST_FAIL";

export const PRODUCT_OWNER_REQUEST = "PRODUCT_OWNER_REQUEST";
export const PRODUCT_OWNER_REQUEST_SUCCESS = "PRODUCT_OWNER_REQUEST_SUCCESS";
export const PRODUCT_OWNER_REQUEST_FAIL = "PRODUCT_OWNER_REQUEST_FAIL";

export const ACCOUNT_REQUEST = "ACCOUNT_REQUEST";
export const ACCOUNT_REQUEST_SUCCESS = "ACCOUNT_REQUEST_SUCCESS";
export const ACCOUNT_REQUEST_FAIL = "ACCOUNT_REQUEST_FAIL";

export const ACCOUNT_UPDATE = "ACCOUNT_UPDATE";
export const ACCOUNT_UPDATE_SUCCESS = "ACCOUNT_UPDATE_SUCCESS";
export const ACCOUNT_UPDATE_FAIL = "ACCOUNT_UPDATE_FAIL";

export const SELL_REQUEST = "SELL_REQUEST";
export const SELL_REQUEST_SUCCESS = "SELL_REQUEST_SUCCESS";
export const SELL_REQUEST_FAIL = "SELL_REQUEST_FAIL";

export const SELL_CANCEL = "SELL_CANCEL";
export const SELL_CANCEL_SUCCESS = "SELL_CANCEL_SUCCESS";
export const SELL_CANCEL_FAIL = "SELL_CANCEL_FAIL";

export const BUY_REQUEST = "BUY_REQUEST";
export const BUY_REQUEST_SUCCESS = "BUY_REQUEST_SUCCESS";
export const BUY_REQUEST_FAIL = "BUY_REQUEST_FAIL";

export const POST_FAVORITE = "POST_FAVORITE";
export const POST_FAVORITE_SUCCESS = "POST_FAVORITE_SUCCESS";
export const POST_FAVORITE_FAIL = "POST_FAVORITE_FAIL";

export const FAVORITE_REQUEST = "FAVORITE_REQUEST";
export const FAVORITE_REQUEST_SUCCESS = "FAVORITE_REQUEST_SUCCESS";
export const FAVORITE_REQUEST_FAIL = "FAVORITE_REQUEST_FAIL";

export const EXCHANGE_RATE = "EXCHANGE_RATE";
export const EXCHANGE_RATE_SUCCESS = "EXCHANGE_RATE_SUCCESS";
export const EXCHANGE_RATE_FAIL = "EXCHANGE_RATE_FAIL";

export const POST_MINT = "POST_MINT";
export const POST_MINT_SUCCESS = "POST_MINT_SUCCESS";
export const POST_MINT_FAIL = "POST_MINT_FAIL";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const BID_LIST_REQUEST = "BID_LIST_REQUEST";
export const BID_LIST_SUCCESS = "BID_LIST_SUCCESS";
export const BID_LIST_FAIL = "BID_LIST_FAIL";

export const HOMEPAGE_REQUEST = "HOMEPAGE_REQUEST";
export const HOMEPAGE_SUCCESS = "HOMEPAGE_SUCCESS";
export const HOMEPAGE_FAIL = "HOMEPAGE_FAIL";
