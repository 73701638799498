import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { FaRegClock } from "react-icons/fa";
import { Link } from "wouter";
import { diffDate, photoUrl } from "../services/content-functions";
import Favorite from "./Favorite";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { mint } from "../services/contract-functions/antique";
import AlertMsg from "./AlertMsg";

const PINATA_URL = process.env.REACT_APP_PINATA_URL;
const IMG_URL = process.env.REACT_APP_IMAGE_URL;

const ExploreCard = ({
  provider,
  signedInAddress = null,
  type = "buy",
  link = true,
  data = {},
  price = null,
  durationDatetime = null,
  widthVal = 3,
  favStatus = true,
  burn = false,
}) => {
  return (
    <Col xs={12} sm={12} md={widthVal} className="min-width-310">
      {link === true ? (
        <Card className="mt-3 block-link">
          <Link to={`/assets/${data.id_pro}`}>
            <Card.Img variant="top" src={photoUrl(data.pro_pic)} />
            <Card.Body>
              <Card.Title>
                <Row>
                  <Col className="name">{data.pro_name}</Col>
                  <Col className="price">
                    {/* <p>
                      Last{" "}
                      <span>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/ETH.png`}
                          height={16}
                        />
                        0.01
                      </span>
                    </p> */}
                    {type === "buy" ? (
                      <TypeBuy data={data} price={price} />
                    ) : type === "bid" ? (
                      <TypeBid
                        data={data}
                        price={price}
                        durationDatetime={durationDatetime}
                      />
                    ) : (
                      <TypeOwner
                        data={data}
                        provider={provider}
                        signedInAddress={signedInAddress}
                      />
                    )}
                  </Col>
                </Row>
              </Card.Title>
            </Card.Body>
          </Link>
          <Card.Footer>
            <Favorite
              provider={provider}
              favorite={data.userfav === 1 ? true : false}
              amount={data.countfav}
              pid={data.id_pro}
            />
          </Card.Footer>
        </Card>
      ) : (
        <Card className="mt-3">
          <Card.Img
            variant="top"
            className={burn === true ? "img-gray" : ""}
            src={photoUrl(data.pro_pic)}
          />
          <Card.Body>
            <Card.Title>
              <Row>
                <Col className="name">{data.pro_name}</Col>
                <Col className="price">
                  {type === "buy" && price !== null ? (
                    <TypeBuy data={data} price={price} />
                  ) : type === "bid" && price !== null ? (
                    <TypeBid
                      data={data}
                      price={price}
                      durationDatetime={durationDatetime}
                    />
                  ) : type === "owner" ? (
                    <TypeOwner
                      data={data}
                      provider={provider}
                      signedInAddress={signedInAddress}
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Card.Title>
            {favStatus === true && (
              <Card.Footer>
                <Favorite
                  provider={provider}
                  favorite={data.userfav === 1 ? true : false}
                  amount={data.countfav}
                  pid={data.id_pro}
                />
              </Card.Footer>
            )}
          </Card.Body>
        </Card>
      )}
    </Col>
  );
};

const TypeBuy = ({ data, price }) => {
  return (
    <div>
      <p>Price</p>
      <p>
        <span className="bold">
          <img
            src={`${process.env.PUBLIC_URL}/images/ETH.png`}
            height={16}
            alt="ETH"
          />
          {price !== null ? price : data.sell_price}
        </span>
      </p>
      {/* <p>
        Last{" "}
        <span>
          <img src={`${process.env.PUBLIC_URL}/images/ETH.png`} height={16} />
          0.01
        </span>
      </p> */}
    </div>
  );
};

const TypeBid = ({ data, price, durationDatetime }) => {
  return (
    <div>
      <p>Min Bid</p>
      <p>
        <span className="bold">
          <img
            src={`${process.env.PUBLIC_URL}/images/ETH.png`}
            height={16}
            alt="ETH"
          />
          {price !== null ? price : data.sell_price}
        </span>
      </p>
      <p>
        <FaRegClock />{" "}
        {durationDatetime !== null
          ? diffDate(durationDatetime)
          : diffDate(data.sell_duration)}
      </p>
    </div>
  );
};

const TypeOwner = ({ data, provider, signedInAddress }) => {
  const [show, setShow] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [showToastError, setShowToastError] = useState(false);

  const toggleShowSuccess = () => setShowToastSuccess(!showToastSuccess);
  const toggleShowError = () => setShowToastError(!showToastError);

  const metadata = {
    name: data.pro_name,
    description: data.pro_des,
    image: IMG_URL + data.pro_pic,
    external_url: PINATA_URL + data.pro_IpfsHash,
    animation_url: PINATA_URL + data.file_IpfsHash,
    attributes: [{ display_type: "string", trait_type: "sku", value: "12345" }],
  };

  const handleMint = async () => {
    setShow(true);
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    const result = await mint(provider, signedInAddress, metadata, data.id_pro);
    console.log("result: ", result);
    if (result === true) {
      await setShow(false);
      await setShowToastSuccess(true);
      await delay(2000);
      window.location = "/account";
    } else {
      setShow(false);
      setShowToastError(true);
    }
  };

  return (
    <div>
      <p>
        {data.pro_status === 4 ? (
          <Button size="md" variant="info" onClick={handleMint}>
            Mint
          </Button>
        ) : (
          data.name_psta
        )}
      </p>

      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Spinner animation="border" size="sm" />
            &nbsp;Minting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please confirm and wait for confirmation on the blockchain.
        </Modal.Body>
      </Modal>

      <AlertMsg
        showToast={showToastSuccess}
        toggleShow={toggleShowSuccess}
        title="Your mint was submitted successfully!"
        msg="success"
      />
      <AlertMsg
        showToast={showToastError}
        toggleShow={toggleShowError}
        title="Your mint was submitted fail !"
        msg="danger"
      />
    </div>
  );
};

export default ExploreCard;
