import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { AUTHEN } from "../actions/types";
import { authenFail, authenSuccess } from "../actions";
import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

const API_URL = process.env.REACT_APP_API_URL;

const authRequest = async (payload) => {
  return await axios
    .post(`${API_URL}/auth/metamask`, payload, {
      headers: {
        "Content-type": "application/json",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

// WORKER
function* authen({ payload }) {
  try {
    const response = yield call(authRequest, payload);

    if (response.message === "Login Successfully") {
      localStorage.setItem("_syart_member", response.access_token);
      yield put(authenSuccess(response));
      window.location = "/account";
    } else {
      yield put(authenFail(response));
    }
  } catch (error) {
    yield put(authenFail(error));
  }
}

// WATCHER
export function* authenWatcher() {
  yield takeEvery(AUTHEN, authen);
}

export default function* rootSaga() {
  yield all([fork(authenWatcher)]);
}
