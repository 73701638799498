import { combineReducers } from "redux";
import { reducer as reduxForm } from "redux-form";
import { connectRouter } from "connected-react-router";
import GetDataReducer from "./GetDataReducer";
import AuthenReducer from "./AuthenReducer";
import GetProductReducer from "./GetProductReducer";
import GetAccountReducer from "./GetAccountReducer";
import GetProductIdReducer from "./GetProductIdReducer";
import GetProductOwnerReducer from "./GetProductOwnerReducer";
import PostFavoriteReducer from "./PostFavoriteReducer";
import ExchangeRateReducer from "./ExchangeRateReducer";
import GetProductFavoriteReducer from "./GetProductFavoriteReducer";
import GetProfileReducer from "./GetProfileReducer";
import GetProductBidingReducer from "./GetProductBidingReducer";
import GetHomepageReducer from "./GetHomepageReducer";

const createRouteReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: reduxForm,
    data: GetDataReducer,
    authen: AuthenReducer,
    product: GetProductReducer,
    account: GetAccountReducer,
    productId: GetProductIdReducer,
    productOwner: GetProductOwnerReducer,
    postFav: PostFavoriteReducer,
    exchangeRate: ExchangeRateReducer,
    productFavorite: GetProductFavoriteReducer,
    profile: GetProfileReducer,
    productBiding: GetProductBidingReducer,
    homepage: GetHomepageReducer,
  });
export default createRouteReducer;
