import React from "react";
import { Image } from "react-bootstrap";

const ProfilePhoto = ({ provider, path = null, size = "md", type = "" }) => {
  return (
    <div className="avatar-profile">
      {type === "blob" ? (
        <Image
          roundedCircle={true}
          thumbnail={true}
          src={path}
          width={150}
          height={150}
          className={`avatar-${size}`}
        />
      ) : !provider ? (
        <Image
          roundedCircle={true}
          thumbnail={true}
          src={
            path === null
              ? `${process.env.PUBLIC_URL}/images/avatar.png`
              : `${process.env.REACT_APP_IMAGE_URL}/accountprofile/${path}`
          }
          width={150}
          height={150}
          className={`avatar-${size}`}
        />
      ) : (
        <Image
          roundedCircle={true}
          thumbnail={true}
          src={
            path === null
              ? `${process.env.PUBLIC_URL}/images/avatar-active.png`
              : `${process.env.REACT_APP_IMAGE_URL}/accountprofile/${path}`
          }
          className={`avatar-${size}`}
          width={150}
          height={150}
        />
      )}
    </div>
  );
};

export default ProfilePhoto;
