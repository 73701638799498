import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAccount } from "../redux/actions";

const Test = ({ signedInAddress, data, getAccount }) => {
  const [params, setParams] = useState("test");

  // const testParameter = (props) => <></>;

  useEffect(() => {
    const fetchData = async () => {
      await getAccount();
      setParams("OK");
    };
    fetchData();
  }, [getAccount]);

  return (
    data !== null && (
      <div className="block-account">
        {signedInAddress}-{params}
      </div>
    )
  );
};

const mapStateToProps = ({ account }) => {
  return { data: account.result };
};

export default connect(mapStateToProps, {
  getAccount,
})(Test);
