// import { BigNumber } from "@ethersproject/bignumber";
import { parseEther, formatEther } from "@ethersproject/units";
import { Contract } from "@ethersproject/contracts";
import Antique from "../../contracts/AntiqueNFT";
import MarketAbi from "../../contracts/Market";

const EXTRACT_ERROR_MESSAGE = /(?<="message":")(.*?)(?=")/g;

const ANTIQUE_NFT_ADDRESS = process.env.REACT_APP_ANTIQUE_NFT_ADDRESS;
const MARKET_ADDRESS = process.env.REACT_APP_MARKET_ADDRESS;
const WEBSERVICE_URL = process.env.REACT_APP_API_URL;

/*
 *  helper functions
 */

function catchError(error) {
  console.error(error.message);

  // try to extract error message, otherwise return raw error
  let formatted_error;

  if (error.message.startsWith("invalid ENS name")) {
    formatted_error = "Missing or invalid parameter.";
  } else if (error.message.startsWith("invalid BigNumber string")) {
    formatted_error = "Invalid number parameter.";
  } else {
    try {
      let errors = JSON.stringify(error).match(EXTRACT_ERROR_MESSAGE);
      formatted_error = errors[errors.length - 1];
    } catch (e) {
      formatted_error = error.message;
    }
  }

  return formatted_error;
}

/*
 *  Smart contract functions
 */

export async function getListings(w3provider) {
  const marketContract = new Contract(MARKET_ADDRESS, MarketAbi, w3provider);
  let listings = [];
  try {
    const res = await marketContract.getListingCount();
    const result = res.toNumber();
    for (var i = 1; i <= result; i++) {
      const item = await marketContract.getListing(i);
      listings.push({
        id: item.tokenId.toNumber(),
        seller: item.seller,
        price: formatEther(item.price),
        status: item.status === 0 ? "Active" : "Sold",
      });
    }
  } catch (error) {
    listings = catchError(error);
  }
  return listings;
}

export async function approveToken(w3provider, tokenId, productId) {
  const signer = w3provider.getSigner();
  const antiqueContract = new Contract(
    ANTIQUE_NFT_ADDRESS,
    Antique.abi,
    w3provider
  );
  const signedAntique = await antiqueContract.connect(signer);
  let result;
  try {
    const approveResult = await signedAntique.approve(MARKET_ADDRESS, tokenId, {
      gasLimit: 250000,
    });
    console.log(approveResult);

    const dataPost = JSON.stringify({
      pro_id: productId,
      tx_hash: approveResult.hash,
    });

    let token;
    try {
      token = await localStorage.getItem("_syart_member");
    } catch (err) {}

    await fetch(WEBSERVICE_URL + "/v1/approve", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: dataPost,
    });

    const res = await w3provider.waitForTransaction(
      approveResult.hash,
      1,
      60000
    );
    console.log(res);
    result = "SUCCESS_MSG";
  } catch (error) {
    result = catchError(error);
  }
  return result;
}

export async function listToken(
  w3provider,
  tokenId,
  productId,
  type,
  price,
  duration,
  listId
) {
  const signer = w3provider.getSigner();
  // const antiqueContract = new Contract(ANTIQUE_NFT_ADDRESS, Antique.abi, w3provider);
  const marketContract = new Contract(MARKET_ADDRESS, MarketAbi, w3provider);
  // const signedAntique = await antiqueContract.connect(signer);
  const signedMarket = await marketContract.connect(signer);
  let result;
  try {
    // const approveResult = await signedAntique.approve(MARKET_ADDRESS, tokenId);
    // console.log(approveResult);
    const listResult = await signedMarket.listToken(
      ANTIQUE_NFT_ADDRESS,
      tokenId,
      parseEther(price),
      { gasLimit: 250000 }
    );

    console.log("listResult sell:", listResult);

    const dataPost = JSON.stringify({
      fk_producct: Number(productId),
      fk_sell_type: type,
      sell_price: Number(price),
      sell_duration: duration,
      tx_hash: listResult.hash,
      list_id: listId,
    });

    // console.log("data sell:", dataPost);

    let token;
    try {
      token = await localStorage.getItem("_syart_member");
    } catch (err) {}

    await fetch(WEBSERVICE_URL + "/v1/sell", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: dataPost,
    });

    const res = await w3provider.waitForTransaction(listResult.hash, 1, 60000);
    console.log("sell waitForTransaction:", res);

    result = "SUCCESS_MSG";
  } catch (error) {
    result = catchError(error);
  }
  return result;
}

export async function buyToken(w3provider, listId, productId, price) {
  const signer = w3provider.getSigner();
  const marketContract = new Contract(MARKET_ADDRESS, MarketAbi, w3provider);
  const signedMarket = await marketContract.connect(signer);
  let result;
  try {
    const buyResult = await signedMarket.buyToken(listId, {
      value: parseEther(price),
      gasLimit: 250000,
    });
    console.log("buyResult: ", buyResult);

    const dataPost = JSON.stringify({
      fk_producct: productId,
      buy_price: price,
      tx_hash: buyResult.hash,
      list_id: listId,
    });

    // console.log("data buy:", dataPost);

    let token;
    try {
      token = await localStorage.getItem("_syart_member");
    } catch (err) {}

    await fetch(WEBSERVICE_URL + "/v1/buy", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: dataPost,
    });

    const res = await w3provider.waitForTransaction(buyResult.hash, 1, 60000);
    console.log("buy waitForTransaction: ", res);

    result = "SUCCESS_MSG";
  } catch (error) {
    result = catchError(error);
  }
  return result;
}

export async function cancelListing(w3provider, listId, pksell, productId) {
  const signer = w3provider.getSigner();
  const marketContract = new Contract(MARKET_ADDRESS, MarketAbi, w3provider);
  const signedMarket = await marketContract.connect(signer);
  let result;
  try {
    const cancelResult = await signedMarket.cancelListing(listId, {
      gasLimit: 250000,
    });
    console.log("cancelResult: ", cancelResult);

    const dataPost = JSON.stringify({
      fk_producct: productId,
      pk_sell: pksell,
      tx_hash: cancelResult.hash,
      list_id: listId,
    });

    let token;
    try {
      token = await localStorage.getItem("_syart_member");
    } catch (err) {}

    await fetch(WEBSERVICE_URL + "/v1/sell/cancel", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: dataPost,
    });

    const res = await w3provider.waitForTransaction(
      cancelResult.hash,
      1,
      60000
    );
    console.log("cancel waitForTransaction: ", res);

    result = "SUCCESS_MSG";
  } catch (error) {
    result = catchError(error);
  }
  return result;
}

export async function auctionCreate(
  w3provider,
  tokenId,
  productId,
  type,
  price,
  duration,
  listId
) {
  const signer = w3provider.getSigner();
  const marketContract = new Contract(MARKET_ADDRESS, MarketAbi, w3provider);
  const signedMarket = await marketContract.connect(signer);
  let result;

  try {
    const contractResult = await signedMarket.auctionCreate(
      ANTIQUE_NFT_ADDRESS,
      tokenId,
      parseEther(price),
      {
        gasLimit: 250000,
      }
    );
    console.log("contractResult: ", contractResult);

    // TODO: post data to Jame's api
    const dataPost = JSON.stringify({
      fk_producct: Number(productId),
      fk_sell_type: type,
      sell_price: Number(price),
      sell_duration: duration,
      tx_hash: contractResult.hash,
      list_id: listId,
    });

    // console.log("data sell:", dataPost);

    let token;
    try {
      token = await localStorage.getItem("_syart_member");
    } catch (err) {}

    await fetch(WEBSERVICE_URL + "/v1/sell", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: dataPost,
    });

    const res = await w3provider.waitForTransaction(
      contractResult.hash,
      1,
      60000
    );
    console.log("sell waitForTransaction:", res);

    result = "SUCCESS_MSG";
  } catch (error) {
    result = catchError(error);
  }

  return result;
}

export async function auctionAccept(
  w3provider,
  listId,
  winnerBuyer,
  offerPrice,
  bidId
) {
  const signer = w3provider.getSigner();
  const marketContract = new Contract(MARKET_ADDRESS, MarketAbi, w3provider);
  const signedMarket = await marketContract.connect(signer);
  let result;

  try {
    const contractResult = await signedMarket.auctionAccept(
      listId,
      winnerBuyer,
      parseEther(offerPrice),
      {
        gasLimit: 250000,
      }
    );
    console.log("contractResult: ", contractResult);

    // TODO: post data to Jame's api
    let token;
    try {
      token = await localStorage.getItem("_syart_member");
    } catch (err) {}

    const dataPost = JSON.stringify({
      bid: bidId,
    });

    const respone = await fetch(WEBSERVICE_URL + "/v1/bid/accept", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: dataPost,
    });
    console.log("api respone:", respone);

    const res = await w3provider.waitForTransaction(
      contractResult.hash,
      1,
      60000
    );
    console.log("sell waitForTransaction:", res);

    result = "SUCCESS_MSG";
  } catch (error) {
    result = catchError(error);
  }

  return result;
}

export async function auctionPayment(w3provider, listId, offerPrice, bidId) {
  const signer = w3provider.getSigner();
  const marketContract = new Contract(MARKET_ADDRESS, MarketAbi, w3provider);
  const signedMarket = await marketContract.connect(signer);
  let result;

  try {
    const contractResult = await signedMarket.auctionPayment(listId, {
      value: parseEther(offerPrice),
      gasLimit: 250000,
    });
    console.log("contractResult: ", contractResult);

    // TODO: post data to Jame's api
    let token;
    try {
      token = await localStorage.getItem("_syart_member");
    } catch (err) {}

    const dataPost = JSON.stringify({
      bid: bidId,
      tx_hash: contractResult.hash,
      list_id: listId,
    });

    const respone = await fetch(WEBSERVICE_URL + "/v1/transfer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: dataPost,
    });
    console.log("api respone:", respone);

    const res = await w3provider.waitForTransaction(
      contractResult.hash,
      1,
      60000
    );
    console.log("sell waitForTransaction:", res);

    result = "SUCCESS_MSG";
  } catch (error) {
    result = catchError(error);
  }

  return result;
}
