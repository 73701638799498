import React from "react";
import { Row } from "react-bootstrap";
import ExploreCard from "./ExploreCard";

const ProfileTabsCreated = ({ provider, signedInAddress, data }) => {
  return data.length > 0 ? (
    <div className="explore-result account-tabs-content">
      <Row className="explore-result pl-2 pr-2">
        {data
          .sort((a, b) => (a.pro_status < b.pro_status ? 1 : -1))
          .map((v, index) => (
            <ExploreCard
              provider={provider}
              signedInAddress={signedInAddress}
              key={index}
              type="owner"
              link={false}
              data={v}
              favStatus={false}
            />
          ))}
      </Row>
    </div>
  ) : (
    <div className="account-tabs-content">
      <h2 className="text-center pt-5 pb-5">No items to display</h2>
    </div>
  );
};

export default ProfileTabsCreated;
