import React, { useEffect } from "react";
import { Container, OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { connect } from "react-redux";
import ProfilePhoto from "../components/ProfilePhoto";
import ProfileTabsCollected from "../components/ProfileTabsCollected";
import ProfileTabsFavorited from "../components/ProfileTabsFavorited";
import { showAddress } from "../services/content-functions";
import { MdFavoriteBorder, MdPhotoFilter } from "react-icons/md";
import { getProfile } from "../redux/actions";
// import moment from "moment";
import LoadingOverlay from "react-loading-overlay";

const Collection = ({
  params,
  provider,
  signedInAddress,
  getProfile,
  profile,
}) => {
  const { address } = params;

  const tooltipCopiedAddress = (props) => (
    <Tooltip {...props} id="tooltip-top">
      Copied to clipboard!
    </Tooltip>
  );

  useEffect(() => {
    async function fetchData() {
      await getProfile(address);
    }
    fetchData();
  }, [getProfile, address]);

  return profile !== null ? (
    <Container className="bg-content-main-white">
      <div className="block-account">
        <div className="cover"></div>

        <div>
          <div className="profile-center text-center">
            <ProfilePhoto path={profile.data.profile_photo_path} />
          </div>
          <div className="account-center text-center">
            <h1>
              {profile.data.name === null ? "Unnamed" : profile.data.name}
            </h1>
            <OverlayTrigger
              key="hover"
              placement="top"
              overlay={<Tooltip id="tooltip-top">Copy</Tooltip>}
            >
              <CopyToClipboard text={signedInAddress}>
                <span className="text-secondary">
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    trigger="click"
                    rootClose={true}
                    onHide
                    rootCloseEvent="mousedown"
                    overlay={tooltipCopiedAddress}
                  >
                    <h5 className="text-gray pointer address-area">
                      {showAddress(profile.data.eth_address)}
                    </h5>
                  </OverlayTrigger>
                </span>
              </CopyToClipboard>
            </OverlayTrigger>

            {/* <h5 className="text-gray">
              Joined {moment(profile.data.created_at).format("MMMM YYYY")}
            </h5> */}
          </div>
        </div>

        <div className="account-content">
          <Tabs defaultActiveKey="collected" id="uncontrolled-tab-example">
            <Tab
              eventKey="collected"
              title={
                <>
                  <MdPhotoFilter size={24} /> Collected{" "}
                  <span className="text-small">
                    {profile.collected.data && profile.collected.data.length > 0
                      ? profile.collected.data.length
                      : ""}
                  </span>
                </>
              }
            >
              <ProfileTabsCollected
                provider={provider}
                data={profile.collected.data ? profile.collected.data : []}
              />
            </Tab>
            <Tab
              eventKey="favorited"
              title={
                <>
                  <MdFavoriteBorder size={24} /> Favorited{" "}
                  <span className="text-small">
                    {profile.fav.data && profile.fav.data.length > 0
                      ? profile.fav.data.length
                      : ""}
                  </span>
                </>
              }
            >
              <ProfileTabsFavorited
                provider={provider}
                data={profile.fav.data ? profile.fav.data : []}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </Container>
  ) : (
    <LoadingOverlay active={true} spinner text="Loading..."></LoadingOverlay>
  );
};

const mapStateToProps = ({ profile }) => {
  return {
    profile: profile.result,
  };
};

export default connect(mapStateToProps, {
  getProfile,
})(Collection);
