import { GET_DATA, GET_DATA_FAIL, GET_DATA_SUCCESS } from "../actions/types";

let result = null;
let error = null;
let fetching = false;

const INIT_STATE = {
  result: result,
  error: error,
  fetching: fetching,
};

function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_DATA: {
      return {
        ...state,
        result: null,
        fetching: true,
        error: null,
      };
    }
    case GET_DATA_SUCCESS: {
      return {
        ...state,
        result: action.payload,
        fetching: false,
        error: null,
      };
    }
    case GET_DATA_FAIL: {
      return {
        ...state,
        result: null,
        fetching: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}

export default reducer;
