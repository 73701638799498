import {
  POST_FAVORITE,
  POST_FAVORITE_FAIL,
  POST_FAVORITE_SUCCESS,
} from "./types";

export const postFav = (payload) => {
  return {
    type: POST_FAVORITE,
    payload,
  };
};

export const postFavSuccess = (payload) => {
  return {
    type: POST_FAVORITE_SUCCESS,
    payload,
  };
};

export const postFavFail = (payload) => {
  return {
    type: POST_FAVORITE_FAIL,
    payload,
  };
};
