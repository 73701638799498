import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_PROFILE } from "../actions/types";
import { getProfileFail, getProfileSuccess } from "../actions";
import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

const API_URL = process.env.REACT_APP_API_URL;

const dataRequest = async (payload) => {
  return await axios
    .get(`${API_URL}/getprofile/${payload}`)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

// WORKER
function* getData({ payload }) {
  // try {
  const response = yield call(dataRequest, payload);

  if (response.data.success === true) {
    yield put(getProfileSuccess(response.data));
  } else {
    yield put(getProfileFail(response));
  }
  // } catch (error) {
  //   yield put(getProductIdFail(error));
  // }
}

// WATCHER
export function* getDataWatcher() {
  yield takeEvery(GET_PROFILE, getData);
}

export default function* rootSaga() {
  yield all([fork(getDataWatcher)]);
}
