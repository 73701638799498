import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { connect } from "react-redux";
import Favorite from "../components/Favorite";
import { getAccount, getProductId } from "../redux/actions";

import {
  MdAccountBalanceWallet,
  MdBallot,
  MdCardGiftcard,
  MdCheckCircle,
  MdChildFriendly,
  MdFireExtinguisher,
  // MdLabel,
  MdLocalOffer,
  MdMoreVert,
  MdNewReleases,
  MdOpenInNew,
  MdOutlineSubject,
  // MdOutlineToc,
  MdPanTool,
  MdRefresh,
  MdSchedule,
  MdShare,
  MdShoppingCart,
  MdSwapHoriz,
  MdSwapVert,
  MdTimeline,
  MdToc,
  MdVerticalSplit,
  // MdViewModule,
} from "react-icons/md";
// import ExploreCard from "../components/ExploreCard";
import DialogCancelListing from "../components/DialogCancelListing";
import DialogCheckout from "../components/DialogCheckout";
// import DialogUnreviewed from "../components/DialogUnreviewed";
import DialogBid from "../components/DialogBid";
import AlertMsg from "../components/AlertMsg";
import { Link } from "wouter";
import LoadingOverlay from "react-loading-overlay";
import {
  addressName,
  convertEthToUsd,
  diffDateStart,
  photoUrl,
} from "../services/content-functions";
import moment from "moment";
import {
  auctionAccept,
  auctionPayment,
  buyToken,
  cancelListing,
} from "../services/contract-functions/market";
import { Line } from "react-chartjs-2";
import { addBiding } from "../services/api/biding";
import DialogVerifyEmail from "../components/DialogVerifyEmail";

const AssetDetail = ({
  params,
  provider,
  loadWeb3Modal,
  data,
  getProductId,
  getAccount,
  account,
  exchange,
}) => {
  const { id } = params;
  const [showCancel, setShowCancel] = useState(false);
  const [showCancelListing, setShowCancelListing] = useState(false);
  const [showToastCancelSuccess, setShowToastCancelSuccess] = useState(false);
  const toggleShowCancelSuccess = () =>
    setShowToastCancelSuccess(!showToastCancelSuccess);
  const [showToastCancelError, setShowToastCancelError] = useState(false);
  const toggleShowCancelError = () =>
    setShowToastCancelError(!showToastCancelError);
  const [showVerify, setShowVerify] = useState(false);

  const handlePopup = () => {
    setShowCancel(true);
  };

  const handleClose = () => {
    setShowCancel(false);
  };

  const refreshData = async () => {
    await getProductId(id);
  };

  const cancelList = async (listId, pksell, productId) => {
    setShowCancel(false);
    setShowCancelListing(true);
    const result = await cancelListing(provider, listId, pksell, productId);

    console.log("result cancel: ", result);

    if (result === "SUCCESS_MSG") {
      setShowCancelListing(false);
      setShowToastCancelSuccess(true);
      refreshData();
    } else {
      setShowCancelListing(false);
      setShowToastCancelError(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getProductId(id);
    };
    fetchData();
  }, [getProductId, id]);

  useEffect(() => {
    const fetchData = async () => {
      if (provider && account === null) {
        await getAccount();
      }
    };
    fetchData();
  }, [getAccount, account, provider]);

  return (!provider && data !== null) ||
    (provider && data !== null && account !== null) ? (
    <Container className="bg-content-main block-fixed">
      {provider &&
        data.data.pro_owner.toLowerCase() === account.eth_address &&
        (data.data.pro_status === 7 ||
          data.data.pro_status === 15 ||
          data.data.pro_status === 16) && (
          <Card>
            <Card.Header>
              <Container className="block-fixed text-right">
                {data.listing.filter((x) => x.status_sell === 1).length > 0 &&
                data.data.pro_status === 7 ? (
                  <>
                    <Button
                      variant="outline-primary"
                      size="lg"
                      className="pl-4 pr-4 mr-2"
                      onClick={handlePopup}
                    >
                      Cancel listing
                    </Button>
                    <DialogCancelListing
                      show={showCancel}
                      handleClose={handleClose}
                      data=""
                      submit={() =>
                        cancelList(
                          data.listing.filter((x) => x.status_sell === 1)[0]
                            .list_id,
                          data.listing.filter((x) => x.status_sell === 1)[0]
                            .pk_sell,
                          data.data.id_pro
                        )
                      }
                    />
                  </>
                ) : data.data.pro_status === 15 ||
                  data.data.pro_status === 16 ? (
                  <>
                    {account.is_active === 1 ? (
                      <>
                        <Button
                          size="lg"
                          className="pl-4 pr-4"
                          variant="warning"
                          onClick={() => setShowVerify(true)}
                        >
                          Redeem & Burn
                        </Button>

                        <Button
                          size="lg"
                          className="pl-4 pr-4 ml-2"
                          onClick={() => setShowVerify(true)}
                        >
                          Sell
                        </Button>
                      </>
                    ) : (
                      <>
                        <Link to={`/assets/${data.data.id_pro}/claim`}>
                          <Button
                            size="lg"
                            className="pl-4 pr-4"
                            variant="warning"
                          >
                            Redeem & Burn
                          </Button>
                        </Link>
                        <Link to={`/assets/${data.data.id_pro}/sell`}>
                          <Button size="lg" className="pl-4 pr-4 ml-2">
                            Sell
                          </Button>
                        </Link>
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </Container>
            </Card.Header>
          </Card>
        )}
      <Row className="mt-4">
        <Col md={5}>
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Favorite
                provider={provider}
                favorite={
                  data !== null && data.data.userfav === 1 ? true : false
                }
                amount={data.data.countfav}
                pid={id}
              />
            </Card.Body>
            <Card.Img variant="top" src={photoUrl(data.data.pro_pic)} />
          </Card>

          <Accordion defaultActiveKey="0" className="mt-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <MdOutlineSubject size={24} className="pr-2" />
                Description
              </Accordion.Header>
              <Accordion.Body>{data.data.pro_des}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <MdVerticalSplit size={24} className="pr-2" /> Abount
              </Accordion.Header>
              <Accordion.Body>{data.data.pro_about}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <MdBallot size={24} className="pr-2" /> Details
              </Accordion.Header>
              <Accordion.Body>{data.data.pro_detail}</Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col md={7} className="asset-info">
          <Row>
            <Col md={6} className="asset-collection">
              {/* <Link to="#">SiamArt</Link> */}
            </Col>
            <Col md={6} className="asset-toolbar">
              <ButtonToolbar aria-label="Toolbar with button groups">
                <ButtonGroup className="me-2" aria-label="First group">
                  <OverlayTrigger
                    key="refresh"
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">Refresh metadata</Tooltip>
                    }
                  >
                    <Button
                      variant="outline-secondary"
                      onClick={() => refreshData()}
                    >
                      <MdRefresh size={24} />
                    </Button>
                  </OverlayTrigger>
                  {id === "owner" ? (
                    <OverlayTrigger
                      key="transfer"
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">Transfer</Tooltip>}
                    >
                      <Button variant="outline-secondary">
                        <MdCardGiftcard size={24} />
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    ""
                  )}
                  <OverlayTrigger
                    key="share"
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">Share</Tooltip>}
                  >
                    <Button variant="outline-secondary">
                      <MdShare size={24} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key="more"
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">More</Tooltip>}
                  >
                    <Button variant="outline-secondary">
                      <MdMoreVert size={24} />
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>{data.data.pro_name}</h1>
            </Col>
          </Row>

          <Row className="pt-4">
            <Col>
              Owned by{" "}
              {provider &&
              data.data.pro_owner.toLowerCase() === account.eth_address ? (
                <Link to="/account">you</Link>
              ) : data.seller.name !== null ? (
                <Link to={`/collection/${data.seller.eth_address}`}>
                  {data.seller.name}
                </Link>
              ) : (
                <Link to={`/collection/${data.seller.eth_address}`}>
                  {addressName(data.seller.eth_address)}
                </Link>
              )}
            </Col>
          </Row>
          {data.listing.filter((x) => x.status_sell === 1).length > 0 &&
          data.listing.filter((x) => x.status_sell === 1)[0].fk_sell_type ===
            1 ? (
            <BuyCard
              provider={provider}
              loadWeb3Modal={loadWeb3Modal}
              data={data}
              disabled={
                provider &&
                data.data.pro_owner.toLowerCase() === account.eth_address
                  ? true
                  : false
              }
              exchange={exchange}
              account={account}
              setShowVerify={setShowVerify}
            />
          ) : data.listing.filter((x) => x.status_sell === 1).length > 0 &&
            data.listing.filter((x) => x.status_sell === 1)[0].fk_sell_type ===
              2 ? (
            <BidCard
              provider={provider}
              loadWeb3Modal={loadWeb3Modal}
              data={data}
              disabled={
                provider &&
                data.data.pro_owner.toLowerCase() === account.eth_address
                  ? true
                  : false
              }
              exchange={exchange}
              account={account}
              setShowVerify={setShowVerify}
            />
          ) : (
            ""
          )}

          <ListingsCard
            lists={data.listing}
            address={provider ? account.eth_address : ""}
            exchange={exchange}
            active={
              data.listing.filter((x) => x.status_sell === 1).length > 0 &&
              data.listing.filter((x) => x.status_sell === 1)[0]
                .fk_sell_type === 1
                ? true
                : false
            }
          />

          {data.listing.filter((x) => x.status_sell === 1).length > 0 &&
            data.listing.filter((x) => x.status_sell === 1)[0].fk_sell_type ===
              2 &&
            data.data.pro_status === 7 && (
              <OffersCard
                provider={provider}
                lists={data.bidlist}
                address={provider ? account.eth_address : ""}
                exchange={exchange}
                status="bid"
                productId={id}
              />
            )}

          {data.listing.length > 0 &&
            data.listing[data.listing.length - 1].fk_sell_type === 2 &&
            (data.data.pro_status === 17 ? (
              <OffersCard
                provider={provider}
                lists={data.bidlist}
                listId={data.data.list_id}
                address={provider ? account.eth_address : ""}
                exchange={exchange}
                productId={id}
                status="accept"
                owner={
                  data.data.pro_owner.toLowerCase() === account.eth_address
                    ? true
                    : false
                }
                // refreshData={refreshData}
              />
            ) : data.data.pro_status === 18 ? (
              <OffersCard
                provider={provider}
                lists={data.bidlist}
                listId={data.data.list_id}
                address={provider ? account.eth_address : ""}
                exchange={exchange}
                status="transfer"
                productId={id}
                owner={
                  data.data.pro_owner.toLowerCase() === account.eth_address
                    ? true
                    : false
                }
              />
            ) : (
              ""
            ))}

          <PriceHistoryCard lists={data.pricehistory} />
        </Col>
      </Row>
      <Row>
        <Col>
          <ItemActivity
            lists={data.itemactivity}
            yourAddress={provider ? account.eth_address : null}
          />
        </Col>
      </Row>
      {/* <MoreCollection /> */}
      <AlertMsg
        showToast={showToastCancelSuccess}
        toggleShow={toggleShowCancelSuccess}
        title="Your cancel listing was submitted successfully"
        msg="success"
      />
      <AlertMsg
        showToast={showToastCancelError}
        toggleShow={toggleShowCancelError}
        title="Your cancel listing was submitted fail!"
        msg="danger"
      />
      <Modal
        show={showCancelListing}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Spinner animation="border" size="sm" />
            &nbsp;Cancel listing
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please confirm and wait for confirmation on the blockchain.
        </Modal.Body>
      </Modal>
      <DialogVerifyEmail showVerify={showVerify} />
    </Container>
  ) : (
    <LoadingOverlay active={true} spinner text="Loading..."></LoadingOverlay>
  );
};

const BuyCard = ({
  provider,
  loadWeb3Modal,
  data,
  disabled,
  exchange,
  account,
  setShowVerify,
}) => {
  const [show, setShow] = useState(false);
  const [showToastSuccess, setShowToastSuccesss] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [buying, setBuying] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    if (!provider) {
      loadWeb3Modal();
    } else {
      setShow(true);
    }
  };
  const toggleShowSuccess = () => setShowToastSuccesss(!showToastSuccess);
  const toggleShowError = () => setShowToastError(!showToastError);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const submit = async () => {
    // console.log("pro_minttoken=", data.data.pro_minttoken);
    // console.log("id_pro=", data.data.id_pro);

    setBuying(true);
    setShow(false);
    const result = await buyToken(
      provider,
      data.data.list_id,
      data.data.id_pro,
      data.listing.filter((x) => x.status_sell === 1)[0].sell_price
    );

    console.log("result buy: ", result);

    if (result === "SUCCESS_MSG") {
      await delay(2000);
      await setBuying(false);
      await setShowToastSuccesss(true);
      delay(1000);
      window.location = "/account";
    } else {
      setBuying(false);
      toggleShowError();
    }
  };

  return (
    <Card className="mt-4 buy-card">
      <Card.Header>
        <MdSchedule size={26} /> Sale ends{" "}
        {moment(
          data.listing.filter((x) => x.status_sell === 1)[0].sell_duration
        ).format("MMM d, Y") +
          " at " +
          moment(
            data.listing.filter((x) => x.status_sell === 1)[0].sell_duration
          ).format("LT")}
      </Card.Header>
      <Card.Body>
        <h3>Current price</h3>
        <h2>
          <img
            src={`${process.env.PUBLIC_URL}/images/ETH.png`}
            height={32}
            alt="ETH"
          />{" "}
          {data.listing.filter((x) => x.status_sell === 1)[0].sell_price}
          <span>
            {" "}
            {exchange !== null &&
              "$" +
                convertEthToUsd(
                  exchange.ETH.USD,
                  data.listing.filter((x) => x.status_sell === 1)[0].sell_price
                )}
          </span>
        </h2>

        {provider && account !== null ? (
          <Button
            className="mt-2"
            disabled={disabled}
            size="lg"
            onClick={() => {
              if (account.is_active === 1) setShowVerify(true);
              else handleShow();
            }}
          >
            <MdAccountBalanceWallet size={24} /> Buy Now
          </Button>
        ) : (
          <Button
            className="mt-2"
            disabled={disabled}
            size="lg"
            onClick={provider && account !== null ? handleShow : loadWeb3Modal}
          >
            <MdAccountBalanceWallet size={24} /> Buy Now
          </Button>
        )}

        <AlertMsg
          showToast={showToastSuccess}
          toggleShow={toggleShowSuccess}
          title="Your buy was submitted successfully"
          msg="success"
        />
        <AlertMsg
          showToast={showToastError}
          toggleShow={toggleShowError}
          title="Your buy was submitted fail !"
          msg="danger"
        />

        <Modal
          show={buying}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>
              <Spinner animation="border" size="sm" />
              &nbsp;Buying
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please confirm and wait for confirmation on the blockchain.
          </Modal.Body>
        </Modal>

        <DialogCheckout
          show={show}
          handleClose={handleClose}
          data={data.data}
          submit={submit}
          listing={data.listing.filter((x) => x.status_sell === 1)[0]}
          exchange={exchange}
        />
      </Card.Body>
    </Card>
  );
};

const BidCard = ({
  provider,
  loadWeb3Modal,
  data,
  disabled,
  exchange,
  account,
  setShowVerify,
}) => {
  const [show, setShow] = useState(false);
  // const [showUnreviewed, setShowUnreviewe] = useState(false);
  const [price, setPrice] = useState("");
  const [biding, setBiding] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);
  // const handleCloseUnreviewed = () => setShowUnreviewe(false);
  // const handleShowUnreviewed = () => setShowUnreviewe(true);
  const [showToastSuccess, setShowToastSuccesss] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const toggleShowSuccess = () => setShowToastSuccesss(!showToastSuccess);
  const toggleShowError = () => setShowToastError(!showToastError);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const submit = async () => {
    setShow(false);
    await setBiding(true);

    const result = await addBiding(
      data.data.tx_hash,
      data.data.list_id,
      data.data.id_pro,
      price
    );

    console.log("result bid: ", result);

    if (result === "SUCCESS_MSG") {
      await delay(3000);
      await setBiding(false);
      await setShowToastSuccesss(true);
      delay(1000);
      window.location = "/assets/" + data.data.id_pro;
    } else {
      setBiding(false);
      toggleShowError();
    }
  };

  const [startDate] = useState(moment(new Date()));
  const [timeEnd] = useState(
    moment(data.listing.filter((x) => x.status_sell === 1)[0].sell_duration)
  );
  const diff = timeEnd.diff(startDate);
  const [diffDuration, setDiffDuration] = useState(moment.duration(diff));
  const MINUTE_MS = 1000;

  useEffect(() => {
    const interval = setInterval(() => {
      const start_date = moment(new Date());
      const diffDate = timeEnd.diff(start_date);
      setDiffDuration(moment.duration(diffDate));
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [timeEnd]);

  return (
    <Card className="mt-4 bid-card">
      <Card.Header>
        <p>
          <MdSchedule size={26} /> Sale ends{" "}
          {moment(
            data.listing.filter((x) => x.status_sell === 1)[0].sell_duration
          ).format("MMM D, Y") +
            " at " +
            moment(
              data.listing.filter((x) => x.status_sell === 1)[0].sell_duration
            ).format("LT")}
        </p>
        <Row>
          <Col md={2}>
            <h5>
              {diffDuration.days()} <p>Days</p>
            </h5>
          </Col>
          <Col md={2}>
            <h5>
              {diffDuration.hours()}
              <p>Hours</p>
            </h5>
          </Col>
          <Col md={2}>
            <h5>
              {diffDuration.minutes()} <p>Minutes</p>
            </h5>
          </Col>
          <Col md={2}>
            <h5>
              {diffDuration.seconds()} <p>Seconds</p>
            </h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <h3>
          Minimum bid{" "}
          {data.bidlist.length > 0 &&
            "(Starting price: " +
              data.listing.filter((x) => x.status_sell === 1)[0].sell_price +
              ")"}
        </h3>
        <h2>
          <img
            src={`${process.env.PUBLIC_URL}/images/ETH.png`}
            height={32}
            alt="ETH"
          />{" "}
          {data.bidlist.length === 0
            ? data.listing.filter((x) => x.status_sell === 1)[0].sell_price
            : data.bidlist.reduce(
                (acc, shot) =>
                  (acc = acc > shot.price_bid ? acc : shot.price_bid),
                0
              )}
          <span>
            {" "}
            (
            {exchange !== null &&
              "$" +
                convertEthToUsd(
                  exchange.ETH.USD,
                  data.listing.filter((x) => x.status_sell === 1)[0].sell_price
                )}
            )
          </span>
        </h2>
        {provider && account !== null ? (
          <Button
            className="mt-2"
            disabled={
              disabled || biding || data.data.pro_status !== 7 ? true : false
            }
            onClick={() => {
              if (account.is_active === 1) setShowVerify(true);
              else handleShow();
            }}
          >
            <MdAccountBalanceWallet size={24} /> Place bid
          </Button>
        ) : (
          <Button
            className="mt-2"
            disabled={
              disabled || biding || data.data.pro_status !== 7 ? true : false
            }
            onClick={provider ? handleShow : loadWeb3Modal}
          >
            <MdAccountBalanceWallet size={24} /> Place bid
          </Button>
        )}

        <AlertMsg
          showToast={showToastSuccess}
          toggleShow={toggleShowSuccess}
          title="Your offer was submitted successfully"
          msg="success"
        />
        <AlertMsg
          showToast={showToastError}
          toggleShow={toggleShowError}
          title="Your offer was submitted fail !"
          msg="danger"
        />

        <DialogBid
          show={show}
          handleClose={handleClose}
          price={price}
          setPrice={setPrice}
          submit={submit}
          exchange={exchange}
          minPrice={
            data.bidlist.length === 0
              ? data.listing.filter((x) => x.status_sell === 1)[0].sell_price
              : data.bidlist.reduce(
                  (acc, shot) =>
                    (acc = acc > shot.price_bid ? acc : shot.price_bid),
                  0
                )
          }
        />

        <Modal
          show={biding}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>
              <Spinner animation="border" size="sm" />
              &nbsp;Biding
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Please confirm and wait for server.</Modal.Body>
        </Modal>

        {/* <DialogUnreviewed
          showUnreviewed={showUnreviewed}
          handleCloseUnreviewed={handleCloseUnreviewed}
          handleShow={handleShow}
          data={data.data}
        /> */}
      </Card.Body>
    </Card>
  );
};

const PriceHistoryCard = ({ lists }) => {
  const data =
    lists.length > 0
      ? {
          labels: lists.map((v) => moment(v.date_create_act).format("M/d")),
          datasets: [
            {
              label: "price",
              data: lists.map((v) => v.untiprice_act),
              fill: false,
              backgroundColor: "#2081E2",
              borderColor: "#2081E2",
            },
          ],
        }
      : null;

  const options = {
    legend: {
      display: false,
    },
    responsive: true,
    // maintainAspectRatio: true,
    layout: {
      padding: {
        top: 5,
        left: 15,
        right: 15,
        bottom: 15,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: { display: true },
          gridLines: {
            display: true,
            drawBorder: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            display: true,
            min: 0,
            max: 1,
            stepSize: 0.25,
          },
          gridLines: {
            display: true,
            drawBorder: false,
          },
        },
      ],
    },
    tooltips: {
      displayColors: false,
      titleFontSize: 16,
      bodyFontSize: 14,
      xPadding: 10,
      yPadding: 10,
      callbacks: {
        label: (tooltipItem) => {
          return `${tooltipItem.value}`;
        },
      },
    },
  };

  return (
    <Accordion defaultActiveKey="0" className="mt-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <MdTimeline size={24} className="pr-2" />
          Price History
        </Accordion.Header>
        <Accordion.Body>
          {/* {JSON.stringify(data)} */}
          {lists.length > 0 ? (
            <Line data={data} options={options} width="100%" height={25} />
          ) : (
            <div className="asset-content">
              <h5 className="text-center pt-5 pb-5">No item activity yet</h5>
            </div>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

const ListingsCard = ({ lists, address, exchange, active }) => {
  return (
    <Accordion defaultActiveKey={active === true ? 0 : ""} className="mt-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <MdLocalOffer size={24} className="pr-2" />
          Listings
        </Accordion.Header>
        {lists.length > 0 ? (
          <Accordion.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>Price</th>
                  <th>USD Price</th>
                  <th>Time</th>
                  <th>From</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {lists
                  .sort((a, b) => b.created_at.localeCompare(a.created_at))
                  .map((val, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/ETH.png`}
                          height={24}
                          alt="ETH"
                        />{" "}
                        {val.sell_price}
                      </td>
                      <td>
                        {exchange !== null &&
                          "$" +
                            convertEthToUsd(exchange.ETH.USD, val.sell_price)}
                      </td>
                      <td>{diffDateStart(val.sell_duration)}</td>
                      <td>
                        {val.user_add.toLowerCase() === address ? (
                          <Link to="/account">you</Link>
                        ) : (
                          <Link to={`/collection/${val.user_add}`}>
                            {addressName(val.user_add)}
                          </Link>
                        )}
                      </td>
                      <td className="text-right">
                        {/* <Button variant="outline-primary">Buy</Button> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Accordion.Body>
        ) : (
          <Accordion.Body>
            <div className="asset-content">
              <h5 className="text-center pt-5 pb-5">No Listings yet</h5>
            </div>
          </Accordion.Body>
        )}
      </Accordion.Item>
    </Accordion>
  );
};

const OffersCard = ({
  provider,
  lists,
  listId = "",
  address,
  exchange,
  status = "bid",
  owner = false,
  productId,
  // refreshData = null,
}) => {
  const [accepting, setAccepting] = useState(false);
  const [transferring, setTransferring] = useState(false);

  const [showToastSuccess, setShowToastSuccesss] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const toggleShowSuccess = () => setShowToastSuccesss(!showToastSuccess);
  const toggleShowError = () => setShowToastError(!showToastError);

  const [showToastTransferSuccess, setShowToastTransferSuccesss] =
    useState(false);
  const [showToastTransferError, setShowToastTransferError] = useState(false);
  const toggleShowTransferSuccess = () =>
    setShowToastTransferSuccesss(!showToastTransferSuccess);
  const toggleShowTransferError = () =>
    setShowToastTransferError(!showToastTransferError);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const acceptRequest = async (winnerBuyer, offerPrice, bidId) => {
    await setAccepting(true);

    const result = await auctionAccept(
      provider,
      listId,
      winnerBuyer,
      offerPrice,
      bidId
    );

    console.log("result bid: ", result);

    if (result === "SUCCESS_MSG") {
      await delay(2000);
      await setAccepting(false);
      await toggleShowSuccess(true);
      delay(2000);
      window.location = "/assets/" + productId;
    } else {
      setAccepting(false);
      toggleShowError();
    }
  };

  const transferRequest = async (bidId, offerPrice) => {
    await setTransferring(true);

    const result = await auctionPayment(provider, listId, offerPrice, bidId);

    console.log("result bid: ", result);

    if (result === "SUCCESS_MSG") {
      await delay(2000);
      await setTransferring(false);
      await toggleShowTransferSuccess(true);
      delay(2000);
      window.location = "/account";
    } else {
      setTransferring(false);
      toggleShowTransferError();
    }
  };

  return (
    <Accordion defaultActiveKey="0" className="mt-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <MdToc size={24} className="pr-2" />
          Bid Records
        </Accordion.Header>
        {lists.length > 0 ? (
          <Accordion.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>Price</th>
                  <th>USD Price</th>
                  <th>Time</th>
                  <th>From</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {lists
                  .sort((a, b) => b.date_timebid.localeCompare(a.date_timebid))
                  .map((val, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/ETH.png`}
                          height={24}
                          alt="ETH"
                        />{" "}
                        {val.price_bid}
                      </td>
                      <td>
                        {exchange !== null &&
                          "$" +
                            convertEthToUsd(exchange.ETH.USD, val.price_bid)}
                      </td>
                      <td>{diffDateStart(val.date_timebid)}</td>
                      <td>
                        {val.fk_address.toLowerCase() === address ? (
                          <Link to="/account">you</Link>
                        ) : (
                          <Link to={`/collection/${val.fk_address}`}>
                            {addressName(val.fk_address)}
                          </Link>
                        )}
                      </td>
                      <td className="text-right">
                        {owner === true &&
                          status !== "bid" &&
                          val.price_bid ===
                            lists.reduce(
                              (acc, shot) =>
                                (acc =
                                  acc > shot.price_bid ? acc : shot.price_bid),
                              0
                            ) && (
                            <Button
                              disabled={status === "accept" ? false : true}
                              size="xs"
                              variant="primary"
                              className="text-capitalize"
                              onClick={() =>
                                status === "accept" &&
                                acceptRequest(
                                  val.fk_address,
                                  val.price_bid,
                                  val.id_bid
                                )
                              }
                            >
                              {status === "transfer" ? "waiting" : status}
                            </Button>
                          )}

                        {address === val.fk_address &&
                          status === "transfer" &&
                          val.price_bid ===
                            lists.reduce(
                              (acc, shot) =>
                                (acc =
                                  acc > shot.price_bid ? acc : shot.price_bid),
                              0
                            ) && (
                            <Button
                              size="xs"
                              variant="primary"
                              className="text-capitalize"
                              onClick={() =>
                                status === "transfer" &&
                                transferRequest(val.id_bid, val.price_bid)
                              }
                            >
                              {status}
                            </Button>
                          )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>

            <AlertMsg
              showToast={showToastSuccess}
              toggleShow={toggleShowSuccess}
              title="Your accept was submitted successfully"
              msg="success"
            />
            <AlertMsg
              showToast={showToastError}
              toggleShow={toggleShowError}
              title="Your accept was submitted fail !"
              msg="danger"
            />

            <AlertMsg
              showToast={showToastTransferSuccess}
              toggleShow={toggleShowSuccess}
              title="Your transfer was submitted successfully"
              msg="success"
            />
            <AlertMsg
              showToast={showToastTransferError}
              toggleShow={toggleShowError}
              title="Your transfer was submitted fail !"
              msg="danger"
            />

            <Modal
              show={accepting}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title>
                  <Spinner animation="border" size="sm" />
                  &nbsp;Accepting
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Please confirm and wait for confirmation on the blockchain.
              </Modal.Body>
            </Modal>

            <Modal
              show={transferring}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title>
                  <Spinner animation="border" size="sm" />
                  &nbsp;Transferring
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Please confirm and wait for confirmation on the blockchain.
              </Modal.Body>
            </Modal>
          </Accordion.Body>
        ) : (
          <Accordion.Body>
            <div className="asset-content">
              <h5 className="text-center pt-5 pb-5">No offers yet</h5>
            </div>
          </Accordion.Body>
        )}
      </Accordion.Item>
    </Accordion>
  );
};

const ItemActivity = ({ lists, yourAddress }) => {
  const [activityName, setActivityName] = useState("All");
  return (
    <Accordion defaultActiveKey="0" className="mt-4 item-activity">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <MdSwapVert size={24} className="pr-2" />
          Item Activity
        </Accordion.Header>
        {lists.length > 0 ? (
          <Accordion.Body>
            <div className="p-4">
              <Form.Select
                size="lg"
                value={activityName}
                onChange={(e) => setActivityName(e.target.value)}
              >
                <option value="All">Filter</option>
                <option value="1">Minted</option>
                <option value="2">List</option>
                {/* <option value="Bid">Bid</option> */}
                <option value="3">Sell</option>
                <option value="4">Cancel</option>
                <option value="5">Approve</option>
                {/* <option value="sold">Sold</option> */}
                <option value="7">Burn</option>
              </Form.Select>
            </div>

            <Table responsive>
              <thead>
                <tr>
                  <th>Event</th>
                  <th>Price</th>
                  <th>Form</th>
                  <th>To</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {lists
                  .sort((a, b) => (a.date_act < b.date_act ? 1 : -1))
                  .filter((x) =>
                    activityName !== "All"
                      ? x.id_event === parseInt(activityName)
                      : x.id_event
                  )
                  .map((v, index) => (
                    <tr key={index}>
                      <td className="text-capitalize">
                        {v.id_event === 3 ? (
                          <MdShoppingCart />
                        ) : v.id_event === 8 ? (
                          <MdPanTool />
                        ) : v.id_event === 2 ? (
                          <MdLocalOffer />
                        ) : v.id_event === 1 ? (
                          <MdChildFriendly />
                        ) : v.id_event === 4 ? (
                          <MdNewReleases />
                        ) : v.id_event === 5 ? (
                          <MdCheckCircle />
                        ) : v.id_event === 6 ? (
                          <MdSwapHoriz />
                        ) : v.id_event === 7 ? (
                          <MdFireExtinguisher />
                        ) : null}
                        {v.name_event}
                      </td>
                      <td>
                        {v.untiprice_act === null ? null : (
                          <>
                            {" "}
                            <img
                              src={`${process.env.PUBLIC_URL}/images/ETH.png`}
                              height={16}
                              alt="ETH"
                            />{" "}
                            {v.untiprice_act}
                          </>
                        )}
                      </td>
                      <td>
                        {yourAddress === v.from_act.toLowerCase() ? (
                          <Link to="/account">you</Link>
                        ) : v.from_act !== "" &&
                          v.from_act !== "NullAddress" ? (
                          <Link
                            to={
                              v.from_act !== "" && v.from_act !== "NullAddress"
                                ? `/collection/${v.from_act}`
                                : "#"
                            }
                          >
                            {addressName(v.from_act)}
                          </Link>
                        ) : (
                          <Link
                            to={
                              v.from_act !== "" && v.from_act !== "NullAddress"
                                ? `/collection/${v.from_act}`
                                : "#"
                            }
                          >
                            {v.from_act}
                          </Link>
                        )}
                      </td>
                      <td>
                        {yourAddress === v.to_act.toLowerCase() ? (
                          <Link to="/account">you</Link>
                        ) : v.to_act !== "" && v.to_act !== "NullAddress" ? (
                          <Link
                            to={
                              v.to_act !== "" && v.to_act !== "NullAddress"
                                ? `/collection/${v.to_act}`
                                : "#"
                            }
                          >
                            {addressName(v.to_act)}
                          </Link>
                        ) : (
                          <Link
                            to={
                              v.to_act !== "" && v.to_act !== "NullAddress"
                                ? `/collection/${v.to_act}`
                                : "#"
                            }
                          >
                            {v.to_act}
                          </Link>
                        )}
                      </td>
                      <td>
                        {(v.id_event === 1 ||
                          v.id_event === 2 ||
                          v.id_event === 6) &&
                        v.tx_hash !== null ? (
                          <a
                            href={`${process.env.REACT_APP_TRANSACTION}0x2415a4cb4ac6b536c2ad46e7ef0125ba26c702f4c9b8fc30e7bd65f978a1113b`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {moment(v.date_act).format("MMM d, Y") +
                              " at " +
                              moment(v.date_act).format("LT")}{" "}
                            <MdOpenInNew size={24} color="blue" />
                          </a>
                        ) : (
                          <>
                            {" "}
                            {moment(v.date_act).format("MMM d, Y") +
                              " at " +
                              moment(v.date_act).format("LT")}{" "}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Accordion.Body>
        ) : (
          <Accordion.Body>
            <div className="asset-content">
              <h5 className="text-center pt-5 pb-5">No Listings yet</h5>
            </div>
          </Accordion.Body>
        )}
      </Accordion.Item>
    </Accordion>
  );
};

// const MoreCollection = () => {
//   return (
//     <Accordion defaultActiveKey="0" className="mt-4">
//       <Accordion.Item eventKey="0">
//         <Accordion.Header>
//           <MdViewModule size={24} className="pr-2" />
//           More From This Owner
//         </Accordion.Header>
//         <Accordion.Body>
//           <Row className="explore-result">
//             <ExploreCard
//               key={0}
//               favorite={true}
//               type="bid"
//               amount="1"
//               data={{
//                 id_pro: 1,
//                 pro_pic: "qejJcNXkfn11VxVeN5LbBwEYc38Anx6P1SGsSRTj.jpg",
//                 pro_name: "Test",
//                 updated_at: "",
//               }}
//             />
//             <ExploreCard
//               key={1}
//               favorite={false}
//               type="buy"
//               data={{
//                 id_pro: 1,
//                 pro_pic: "qejJcNXkfn11VxVeN5LbBwEYc38Anx6P1SGsSRTj.jpg",
//                 pro_name: "Test",
//                 updated_at: "",
//               }}
//             />
//             <ExploreCard
//               key={2}
//               favorite={false}
//               type="buy"
//               data={{
//                 id_pro: 1,
//                 pro_pic: "qejJcNXkfn11VxVeN5LbBwEYc38Anx6P1SGsSRTj.jpg",
//                 pro_name: "Test",
//                 updated_at: "",
//               }}
//             />
//             <ExploreCard
//               key={3}
//               favorite={true}
//               type="bid"
//               amount="3"
//               data={{
//                 id_pro: 1,
//                 pro_pic: "qejJcNXkfn11VxVeN5LbBwEYc38Anx6P1SGsSRTj.jpg",
//                 pro_name: "Test",
//                 updated_at: "",
//               }}
//             />
//           </Row>
//           <Row className="accordion-footer mt-4">
//             <Col className="text-center">
//               <Button variant="outline-primary">View all</Button>
//             </Col>
//           </Row>
//         </Accordion.Body>
//       </Accordion.Item>
//     </Accordion>
//   );
// };

const mapStateToProps = ({ productId, account, exchangeRate }) => {
  return {
    data: productId.result,
    account: account.result,
    exchange: exchangeRate.result,
  };
};

export default connect(mapStateToProps, {
  getProductId,
  getAccount,
})(AssetDetail);
