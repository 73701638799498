import { claim } from "../services/contract-functions/antique";

const Burning = ({ provider }) => {
    const handleBurn = async () => {
        await claim(provider, 1);
    }

    return (
        <div>
            <h2>Burn NFT</h2>
            <button className="btn btn-primary" onClick={handleBurn}>Burn</button>
        </div>
    );
}

export default Burning;