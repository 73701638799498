import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
// import { mint, MintedListener } from "../services/contract-functions";
import { mint } from "../services/contract-functions/antique";

const Minting = ({ provider, signedInAddress }) => {
    const [show, setShow] = useState(false);

    const metadata = { "name": "Dave Starbelly", "description": "Friendly OpenSea Creature that enjoys long swims in the ocean.", "image": "https://gateway.pinata.cloud/ipfs/QmSvAKZe7SZyBmLqKaWr9msLAmRXhxw37zWuca5v1bWbQm", "external_url": "https://gateway.pinata.cloud/ipfs/QmSvAKZe7SZyBmLqKaWr9msLAmRXhxw37zWuca5v1bWbQm", "animation_url": "https://gateway.pinata.cloud/ipfs/QmSvAKZe7SZyBmLqKaWr9msLAmRXhxw37zWuca5v1bWbQm", "attributes": [{ "display_type": "string", "trait_type": "sku", "value": "12345" }] };

    const handleMint = async () => {
        setShow(true);
        // await mint(provider, signedInAddress, 'https://gateway.pinata.cloud/ipfs/QmanAfy7SghBJi6XV7XAv2hCwhyD2GFLN45tXR8jyZ1uMG');
        // await MintedListener(provider, function (owner, tokenId) {
        //     console.log(`Event - Minted by - ${owner} token id - ${tokenId}`);
        //     setShow(false);
        //     window.location = "dashboard";
        // });
        const result = await mint(provider, signedInAddress, "https://gateway.pinata.cloud/ipfs/Qmcfzw8VAceT5iUucd6cSEdsnF8LC1S5VU7XZkMF5bFhFM", 1);
        if (result) {
            setShow(false);
            window.location = "dashboard";
        }
    }

    return (
        <>
            <h2>Mint NFT</h2>
            <p className="mb-1">Image</p>
            <img src="https://gateway.pinata.cloud/ipfs/QmSvAKZe7SZyBmLqKaWr9msLAmRXhxw37zWuca5v1bWbQm" alt="" width={100} />
            <p className="mb-1">Video</p>
            <img src="https://gateway.pinata.cloud/ipfs/QmSvAKZe7SZyBmLqKaWr9msLAmRXhxw37zWuca5v1bWbQm" alt="" width={100} />
            <p className="mb-1">Metadata</p>
            <pre>
                {JSON.stringify(metadata, null, 4)}
            </pre>
            <button className="btn btn-primary" onClick={handleMint}>Mint</button>

            <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title>
                        <Spinner animation="border" size="sm" />
                        &nbsp;Minting
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>Please confirm and wait for confirmation on the blockchain.</Modal.Body>
            </Modal>
        </>
    );
}

export default Minting;