import {
  ACCOUNT_REQUEST,
  ACCOUNT_REQUEST_FAIL,
  ACCOUNT_REQUEST_SUCCESS,
} from "./types";

export const getAccount = () => {
  return {
    type: ACCOUNT_REQUEST,
  };
};

export const getAccountSuccess = (payload) => {
  return {
    type: ACCOUNT_REQUEST_SUCCESS,
    payload,
  };
};

export const getAccountFail = (payload) => {
  return {
    type: ACCOUNT_REQUEST_FAIL,
    payload,
  };
};
