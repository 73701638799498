import React from "react";
import { Button, Col, Figure, Modal, Row } from "react-bootstrap";
import { Link } from "wouter";
import {
  convertEthToUsd,
  photoUrl,
  showAddress,
} from "../services/content-functions";

const DialogCheckout = ({
  show,
  handleClose,
  data,
  listing,
  submit,
  exchange,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      className="dialog-checkout"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Complete checkout</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5 pt-4 pb-2 checkout">
        <Row className="border-bottom pd">
          <Col md={6} className="text-bold nopadding">
            Item
          </Col>
          <Col md={6} className="text-right text-bold nopadding">
            Subtotal
          </Col>
        </Row>
        <Row className="border-bottom pd">
          <Col md={6}>
            <Row>
              <Col md={4}>
                <Figure>
                  <Figure.Image
                    width={100}
                    height={100}
                    alt="image"
                    src={photoUrl(data.pro_pic)}
                  />
                </Figure>
              </Col>
              <Col className="align-middle">
                <Link to={`/collection/${data.pro_owner}`}>
                  {showAddress(data.pro_owner)}
                </Link>
                {/* <p className="text-bold">Ethermeow</p> */}
              </Col>
            </Row>
          </Col>
          <Col md={6} className="text-right nopadding">
            <img
              src={`${process.env.PUBLIC_URL}/images/ETH.png`}
              height={16}
              alt="ETH"
            />{" "}
            {listing.sell_price}
            <p className="text-normal">
              {exchange !== null &&
                "$" + convertEthToUsd(exchange.ETH.USD, listing.sell_price)}
            </p>
          </Col>
        </Row>
        <Row className="pd">
          <Col md={6} className="text-bold nopadding">
            Total
          </Col>
          <Col md={6} className="text-right text-bold nopadding">
            <img
              src={`${process.env.PUBLIC_URL}/images/ETH.png`}
              height={16}
              alt="ETH"
            />{" "}
            {listing.sell_price}
            <p className="text-normal">$0.01</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {/* <Link to="/account"> */}
        <Button size="lg" variant="primary" onClick={submit}>
          Confirm checkout
        </Button>
        {/* </Link> */}
      </Modal.Footer>
    </Modal>
  );
};

export default DialogCheckout;
