import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { ACCOUNT_REQUEST } from "../actions/types";
import { getAccountFail, getAccountSuccess } from "../actions";
import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

const API_URL = process.env.REACT_APP_API_URL;

const dataRequest = async () => {
  let token;
  try {
    token = await localStorage.getItem("_syart_member");
  } catch (err) {}

  return await axios
    .get(`${API_URL}/v1/account`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

// WORKER
function* getData() {
  // try {
  const response = yield call(dataRequest);

  if (response.data.success === true) {
    yield put(getAccountSuccess(response.data.data));
  } else {
    yield put(getAccountFail(response));
  }
  // } catch (error) {
  //   yield put(getAccountFail(error));
  // }
}

// WATCHER
export function* getDataWatcher() {
  yield takeEvery(ACCOUNT_REQUEST, getData);
}

export default function* rootSaga() {
  yield all([fork(getDataWatcher)]);
}
