const WEBSERVICE_URL = process.env.REACT_APP_API_URL;
const EXTRACT_ERROR_MESSAGE = /(?<="message":")(.*?)(?=")/g;

function catchError(error) {
  console.error(error.message);

  // try to extract error message, otherwise return raw error
  let formatted_error;

  if (error.message.startsWith("invalid ENS name")) {
    formatted_error = "Missing or invalid parameter.";
  } else if (error.message.startsWith("invalid BigNumber string")) {
    formatted_error = "Invalid number parameter.";
  } else {
    try {
      let errors = JSON.stringify(error).match(EXTRACT_ERROR_MESSAGE);
      formatted_error = errors[errors.length - 1];
    } catch (e) {
      formatted_error = error.message;
    }
  }

  return formatted_error;
}

export async function redeem(productId, name, address) {
  let result;
  try {
    let token;
    try {
      token = await localStorage.getItem("_syart_member");
    } catch (err) {}

    const dataPost = JSON.stringify({
      pro_id: productId,
      name: name,
      address: address,
    });

    const res = await fetch(WEBSERVICE_URL + "/v1/product/redeem", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: dataPost,
    });

    console.log("res bid ===> ", res);
    result = "SUCCESS_MSG";
  } catch (error) {
    result = catchError(error);
  }
  return result;
}
