import React, { useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip, Button } from "react-bootstrap";
import { FaHeart } from "react-icons/fa";
import { connect } from "react-redux";
import { postFav } from "../redux/actions";

function Favorite({ provider, favorite, amount = 0, pid = null, postFav }) {
  const [fav, setFav] = useState(favorite);
  const [favamount, setFavamount] = useState(amount);

  const postFavReq = async () => {
    await postFav({ fk_producct: pid });
    setFavamount(fav ? favamount - 1 : favamount + 1);
    setFav(!fav);
  };

  return (
    <Row>
      <Col className={fav ? "text-right favorite" : "text-right"}>
        <OverlayTrigger
          key="favorite"
          placement="top"
          overlay={
            <Tooltip id="tooltip-top">
              {fav ? "Unfavorite" : "Favorite"}
            </Tooltip>
          }
        >
          <Button
            variant="outline-light"
            onClick={provider ? postFavReq.bind(null) : null}
            className="btn-fav"
          >
            <FaHeart className={fav ? "fav active" : "fav"} />
          </Button>
        </OverlayTrigger>{" "}
        {favamount}
      </Col>
    </Row>
  );
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { postFav })(Favorite);
