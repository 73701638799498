import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { PRODUCT_REQUEST } from "../actions/types";
import { getProductFail, getProductSuccess } from "../actions";
import axios from "axios";
import axiosRetry from "axios-retry";
// import { authorizationHeader } from "../../utils/apiUtil";

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

const API_URL = process.env.REACT_APP_API_URL;

const dataRequest = async () => {
  let token = null;
  try {
    token = await localStorage.getItem("_syart_member");
  } catch (err) {}

  if (token === null) {
    return await axios
      .get(`${API_URL}/product`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  } else {
    return await axios
      .get(`${API_URL}/v1/product`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }
};

// WORKER
function* getData() {
  try {
    const response = yield call(dataRequest);

    if (response.data.success === true) {
      yield put(getProductSuccess(response.data.data));
    } else {
      yield put(getProductFail(response));
    }
  } catch (error) {
    yield put(getProductFail(error));
  }
}

// WATCHER
export function* getDataWatcher() {
  yield takeEvery(PRODUCT_REQUEST, getData);
}

export default function* rootSaga() {
  yield all([fork(getDataWatcher)]);
}
