import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegClipboard } from "react-icons/fa";
import { Link } from "wouter";
import SlidingPane from "react-sliding-pane";
import { getEexchangeRate, getProfile, getHomepage } from "../redux/actions";
import {
  Col,
  Container,
  Dropdown,
  DropdownButton,
  ListGroup,
  Row,
} from "react-bootstrap";
import {
  MdAccountBalanceWallet,
  MdAccountCircle,
  MdCheck,
  MdLogout,
  // MdSync,
} from "react-icons/md";
import { convertEthToUsd, showAddress } from "../services/content-functions";
import ProfilePhoto from "./ProfilePhoto";
import { connect } from "react-redux";

const WalletButton = ({ provider, loadWeb3Modal, slideRight }) => {
  return (
    <div>
      {!provider ? (
        <Nav.Link
          eventKey="wallet"
          className="border"
          onClick={async () => {
            await loadWeb3Modal();
            window.location.replace("/sign");
          }}
        >
          CONNECT WALLET
        </Nav.Link>
      ) : (
        <Nav.Link eventKey="wallet" onClick={slideRight}>
          <MdAccountBalanceWallet size={32} />
        </Nav.Link>
      )}
    </div>
  );
};

const tooltipCopiedAddress = (props) => (
  <Tooltip {...props}>Copied to clipboard!</Tooltip>
);

const SlidingPaneRight = ({
  provider,
  isPaneOpenRight,
  logoutOfWeb3Modal,
  signedInAddress,
  tooltipCopiedAddress,
  balance,
  tootipClose,
  exchange,
}) => (
  <Container>
    <SlidingPane
      className="slide-pane-header"
      overlayClassName="custom-sliding-pane"
      isOpen={isPaneOpenRight}
      title={
        <Row className="text-bold">
          <Col>
            <DropdownButton
              variant="light"
              id="dropdown-variants-light"
              title={
                <span className="text-bold">
                  <MdAccountCircle size={26} /> My Wallet
                </span>
              }
            >
              <Dropdown.Item href="#" className="text-capitalize">
                <img
                  src={`${process.env.PUBLIC_URL}/icons/metamask.png`}
                  width={24}
                  alt="metamask"
                />{" "}
                {provider && provider.connection?.url}
                {"    "}
                <MdCheck className="fload-right icon-green" size={24} />
              </Dropdown.Item>
              <Dropdown.Item onClick={logoutOfWeb3Modal}>
                <MdLogout size={24} /> Logout
              </Dropdown.Item>
              {/* <Dropdown.Item href="#" className="border-radius">
                <MdSync size={24} /> Refresh funds
              </Dropdown.Item> */}
            </DropdownButton>
          </Col>
          <Col className="text-right">
            <CopyToClipboard text={signedInAddress}>
              <span className="text-secondary">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  rootClose={true}
                  delay={{ show: 250, hide: 400 }}
                  overlay={tooltipCopiedAddress}
                >
                  <span
                    className="text-secondary"
                    style={{ cursor: "pointer" }}
                  >
                    {signedInAddress !== "" ? showAddress(signedInAddress) : ""}
                    &nbsp;
                    <FaRegClipboard size={20} />
                  </span>
                </OverlayTrigger>
              </span>
            </CopyToClipboard>
          </Col>
        </Row>
      }
      from="right"
      width="500px"
      onRequestClose={tootipClose}
    >
      <ListGroup className="right-wallet">
        <ListGroup.Item className="text-center p-3">
          <p className="text-gray">Total balance</p>
          <h3 className="text-bold">
            {exchange !== null &&
              "$" +
                convertEthToUsd(
                  exchange.ETH.USD,
                  parseFloat(balance).toFixed(4)
                )}{" "}
            USD
          </h3>
        </ListGroup.Item>
        <ListGroup.Item className="add-funds">Add funds</ListGroup.Item>
      </ListGroup>

      <ListGroup className="right-wallet mt-4 right-wallet-lists">
        <ListGroup.Item>
          <Row>
            <Col md={2}>
              <img
                src={`${process.env.PUBLIC_URL}/images/ETH.png`}
                width={40}
                alt="ETH"
              />
            </Col>
            <Col>
              <h5 className="no-line-height">ETH</h5>
              <p className="no-line-height text-capitalize">
                {provider && provider._network && provider._network.name}
              </p>
            </Col>
            <Col className="text-right">
              <h5 className="text-bold no-line-height">
                {parseFloat(balance).toFixed(4)}
              </h5>
              <p className="text-gray no-line-height">
                {exchange !== null &&
                  "$" +
                    convertEthToUsd(
                      exchange.ETH.USD,
                      parseFloat(balance).toFixed(4)
                    )}{" "}
                USD
              </p>
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
    </SlidingPane>
  </Container>
);

const NavigationBar = ({
  provider,
  balance,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  signedInAddress,
  getEexchangeRate,
  exchange,
  getProfile,
  profile,
  getHomepage,
  homepage,
}) => {
  useEffect(() => {
    async function fetchDataHomepage() {
      await getHomepage();
    }

    if (homepage === null) {
      fetchDataHomepage();
    }
  }, [getHomepage, homepage]);

  useEffect(() => {
    async function fetchData() {
      await getEexchangeRate();
    }

    if (exchange === null) {
      fetchData();
    }

    const intervalExchange = setInterval(() => {
      fetchData();
      console.log("exchange=", exchange);
    }, 100000);

    return () => clearInterval(intervalExchange);
  }, [getEexchangeRate, exchange]);

  useEffect(() => {
    async function fetchData() {
      await getProfile(signedInAddress);
    }
    if (signedInAddress !== "") {
      fetchData();
    }
  }, [getProfile, signedInAddress]);

  const [state, setState] = useState({
    isPaneOpenRight: false,
  });

  const slideRight = () => {
    setState({ isPaneOpenRight: !state.isPaneOpenRight });
  };

  const tootipClose = () => {
    setState({ isPaneOpenRight: false });
  };

  // console.log("provider=", provider);

  return (
    homepage !== null && (
      <div
        className="bg-navbar"
        style={{
          background: `url(${process.env.REACT_APP_IMAGE_URL}${homepage.headermenu.Headerbackground.logo_menu}`,
        }}
      >
        <Navbar expand="lg">
          <Container className="block-fixed">
            <Link href="/">
              <Navbar.Brand>
                <img
                  src={
                    homepage.headermenu.logo.logo_menu === ""
                      ? `${process.env.PUBLIC_URL}/icons/logo.png`
                      : homepage.headermenu.logo.path +
                        homepage.headermenu.logo.logo_menu
                  }
                  height={45}
                  alt="logo"
                />
              </Navbar.Brand>{" "}
            </Link>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end right"
            >
              <Nav>
                {homepage.headermenu.menu.map((x, index) => (
                  <Link key={index} href={x.link_menu}>
                    <Nav.Link eventKey="assets" className="text-uppercase">
                      {x.name_menu_en}
                    </Nav.Link>
                  </Link>
                ))}
                {/* <Link href="/alpha-nft">
                  <Nav.Link eventKey="alpha-nft">ALPHA NFT</Nav.Link>
                </Link>
                <Link href="/faq">
                  <Nav.Link eventKey="faq">FAQ</Nav.Link>
                </Link> */}
                <Link href="#">
                  <Nav.Link eventKey="EN/CN">
                    {homepage.headermenu.lang}
                  </Nav.Link>
                </Link>
                {signedInAddress !== "" && (
                  <Link href="/account">
                    <Nav.Link eventKey="account">
                      <ProfilePhoto
                        provider={provider}
                        size="sm"
                        path={
                          profile !== null
                            ? profile.data.profile_photo_path
                            : ""
                        }
                      />
                    </Nav.Link>
                  </Link>
                )}
                <WalletButton
                  provider={provider}
                  loadWeb3Modal={loadWeb3Modal}
                  logoutOfWeb3Modal={logoutOfWeb3Modal}
                  slideRight={slideRight}
                  getHomepage={getHomepage}
                  homepage={homepage}
                />
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <SlidingPaneRight
          provider={provider}
          isPaneOpenRight={state.isPaneOpenRight}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
          signedInAddress={signedInAddress}
          tooltipCopiedAddress={tooltipCopiedAddress}
          balance={balance}
          tootipClose={tootipClose}
          exchange={exchange}
        />
      </div>
    )
  );
};

const mapStateToProps = ({ exchangeRate, profile, homepage }) => {
  return {
    exchange: exchangeRate.result,
    profile: profile.result,
    homepage: homepage.result,
  };
};

export default connect(mapStateToProps, {
  getEexchangeRate,
  getProfile,
  getHomepage,
})(NavigationBar);
