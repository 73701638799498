import React, {
  forwardRef,
  useEffect,
  // useCallback,
  useImperativeHandle,
  useState
} from "react";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import { getTokenBalanceOf } from "../services/contract-functions/antique";
import Asset from "../components/Asset";

export const Dashboard = forwardRef(({ provider, signedInAddress }, ref) => {
  const [assets, setAssets] = useState([]);
  const [fetchingTokens, setFetchingTokens] = useState(false);
  const [error, setError] = useState("");

  // Allows the parent component to refresh balances on clicking the Dashboard button in the navigation
  useImperativeHandle(ref, () => ({
    refresh() {
      handleRefresh();
    }
  }));

  const handleRefresh = () => {
    // clear localStorage
    const localStorage = window.localStorage;
    localStorage.setItem('token_balances', null);
    setFetchingTokens(true);
    fetchBalances();
  }

  // const fetchBalances = useCallback(async () => {
  //   console.log("fetchBalances");
  //   // let v = await provider.getVault();
  //   await getTokenBalanceOf(provider, signedInAddress)
  //   setFetchingTokens(false);
  //   setError("");
  // }, [provider, signedInAddress]);

  const fetchBalances = async () => {
    const assets = await getTokenBalanceOf(provider, signedInAddress);
    console.log("fetchBalances", assets);
    if (Array.isArray(assets)) {
      setAssets(assets);
      setFetchingTokens(false);
      setError("");
    } else {
      setAssets([]);
      setFetchingTokens(false);
      setError("No assets found");
    }
  };

  useEffect(() => {
    const fetchBalances = async () => {
      const assets = await getTokenBalanceOf(provider, signedInAddress);
      if (Array.isArray(assets)) {
        console.log("fetchBalances", assets);
        setAssets(assets);
        setFetchingTokens(false);
        setError("");
      } else {
        setAssets([]);
        setFetchingTokens(false);
        setError("No assets found");
      }
    };
    fetchBalances();
  }, [provider, signedInAddress]);

  return (
    <>
      <h2>Assets</h2>
      <p className="mb-1">View your assets you've minted.</p>
      <p className="text-danger">{error}</p>
      <div className={fetchingTokens ? "dimmed" : ""}>
        {fetchingTokens && (
          <div className="text-center my-4">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        <div className="mb-4">
          <Row xs={3} md={3} lg={3}>
            {assets.map(function (asset, i) {
              return <Asset asset={asset} key={i}>{asset}</Asset>;
            })}
          </Row>
        </div>
      </div>
    </>
  );
});

export default Dashboard;