import { Contract } from "@ethersproject/contracts";
import Antique from "../../contracts/AntiqueNFT";

const EXTRACT_ERROR_MESSAGE = /(?<="message":")(.*?)(?=")/g;

const ANTIQUE_NFT_ADDRESS = process.env.REACT_APP_ANTIQUE_NFT_ADDRESS;
const WEBSERVICE_URL = process.env.REACT_APP_API_URL;

/*
 *  helper functions
 */

function catchError(error) {
  console.error(error.message);

  // try to extract error message, otherwise return raw error
  let formatted_error;

  if (error.message.startsWith("invalid ENS name")) {
    formatted_error = "Missing or invalid parameter.";
  } else if (error.message.startsWith("invalid BigNumber string")) {
    formatted_error = "Invalid number parameter.";
  } else {
    try {
      let errors = JSON.stringify(error).match(EXTRACT_ERROR_MESSAGE);
      formatted_error = errors[errors.length - 1];
    } catch (e) {
      formatted_error = error.message;
    }
  }

  return formatted_error;
}

/*
 *  Smart contract functions
 */

export async function mint(w3provider, account, metadataUrl, productId) {
  const signer = w3provider.getSigner();
  const antiqueContract = new Contract(
    ANTIQUE_NFT_ADDRESS,
    Antique.abi,
    w3provider
  );
  const signed = await antiqueContract.connect(signer);
  try {
    const tx = await signed.mint(account, metadataUrl);

    const dataPost = JSON.stringify({
      pro_id: productId,
      tx_hash: tx.hash,
    });
    console.log("data", dataPost);

    let token;
    try {
      token = await localStorage.getItem("_syart_member");
    } catch (err) {}

    await fetch(WEBSERVICE_URL + "/v1/productsmint", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: dataPost,
    });

    const res = await w3provider.waitForTransaction(tx.hash, 1, 60000);
    if (res.status === 1) {
      return true;
    }
  } catch (error) {
    return catchError(error);
  }
}

export async function claim(w3provider, tokenId, newClaimedUrl) {
  const signer = w3provider.getSigner();
  const antiqueContract = new Contract(
    ANTIQUE_NFT_ADDRESS,
    Antique.abi,
    w3provider
  );
  const signed = await antiqueContract.connect(signer);
  try {
    const tx = await signed.claim(
      tokenId,
      newClaimedUrl
    );
    const res = await w3provider.waitForTransaction(tx.hash, 1, 60000);
    if (res.status === 1) {
      return true;
    }
  } catch (error) {
    return catchError(error);
  }
}

export async function totalSupply(w3provider) {
  const antiqueContract = new Contract(
    ANTIQUE_NFT_ADDRESS,
    Antique.abi,
    w3provider
  );

  var result;
  try {
    const res = await antiqueContract.totalSupply();
    result = res.toNumber();
  } catch (error) {
    result = catchError(error);
  }
  return result;
}

export async function getCollections(w3provider, account) {
  const antiqueContract = new Contract(
    ANTIQUE_NFT_ADDRESS,
    Antique.abi,
    w3provider
  );
  let collections = [];
  try {
    const res = await antiqueContract.totalSupply();
    const result = res.toNumber();
    for (var i = 1; i <= result; i++) {
      const item = await antiqueContract.ownerOf(i);
      if (item.toLowerCase() === account.toLowerCase()) {
        collections.push(i);
      }
    }
  } catch (error) {
    collections = catchError(error);
  }
  return collections;
}

export async function getTokenBalanceOf(w3provider, account) {
  const contract = new Contract(ANTIQUE_NFT_ADDRESS, Antique.abi, w3provider);
  var assets = [];
  try {
    const fetchedBalance = await contract.balanceOf(account);
    const balance = fetchedBalance.toNumber();
    console.log("Balance:", balance);
    for (var i = 0; i < balance; i++) {
      const tokenMeta = await contract.tokenMeta(i + 1);
      console.log("TokenMeta:", tokenMeta);
      assets.push(tokenMeta);
    }
  } catch (error) {
    assets = error.message;
  }
  return assets;
}
