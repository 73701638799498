import React from "react";
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { convertEthToUsd } from "../services/content-functions";

const DialogBid = ({
  show,
  handleClose,
  price,
  setPrice,
  submit,
  exchange,
  minPrice,
}) => {
  const onKep = (e) => {
    const re = /^[0-9]*[.]*[0-9]?/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPrice(e.target.value);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      className="dialog-checkout"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Place a bid</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5 pt-4 pb-2 checkout">
        <Row>
          <Col md={12}>Price</Col>
          <Col md={12}>
            <InputGroup className="mb-1">
              <InputGroup.Text className="pr-5">
                <img
                  src={`${process.env.PUBLIC_URL}/images/ETH.png`}
                  height={20}
                  alt="ETH"
                />{" "}
                ETH
              </InputGroup.Text>
              <FormControl
                size="lg"
                type="number"
                placeholder="Amount"
                value={price}
                // onKeyPress={(e) => onKep(e)}
                onChange={(e) => onKep(e)}
              />
              <InputGroup.Text className="pl-5">
                {exchange !== null &&
                  "$" +
                    convertEthToUsd(exchange.ETH.USD, price === "" ? 0 : price)}
              </InputGroup.Text>
            </InputGroup>
            <p>min bid: {minPrice}</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="lg"
          disabled={price === "" || price <= minPrice ? true : false}
          variant="primary"
          onClick={submit}
        >
          Place Bid
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DialogBid;
